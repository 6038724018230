import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule, inject, provideAppInitializer } from '@angular/core';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask, provideNgxMask } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FieldTextComponent } from './components/fields/field-text/field-text.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldToggleComponent } from './components/fields/field-toggle/field-toggle.component';
import { FieldTextareaComponent } from './components/fields/field-textarea/field-textarea.component';
import { SpinnerComponent } from './components/misc/spinner/spinner.component';
import { ApiModule, Configuration } from './utils/api';
import { AvatarComponent } from './components/common/avatar/avatar.component';
import { PopoutPanelComponent } from './components/layout/popout-panel/popout-panel.component';
import { FieldSwitchComponent } from './components/fields/field-switch/field-switch.component';
import { FilterButtonComponent } from './components/common/filter-button/filter-button.component';
import { TableComponent } from './components/common/table/table.component';
import { PopoverComponent } from './components/common/popover/popover.component';
import { ClickOutsideDirective } from './utils/directives';
import {
    displayPipe,
    formatTsPipe,
    customCurrencyPipe,
    percentagePipe,
    TranslatePipe,
    ArrayPipe,
    callbackPipe,
    SafePipe,
    booleanDisplayPipe,
    floatDisplayPipe,
    numberDisplayPipe
} from './utils/pipes';
import { FilterButtonGroupComponent } from './components/common/filter-button-group/filter-button-group.component';
import { EmptyStateComponent } from './components/common/empty-state/empty-state.component';
import { GlobalErrorHandler } from './utils/errorHandler';
import { ModalSidePanelComponent } from './components/layout/modal-side-panel/modal-side-panel.component';
import { SideNavigationComponent } from './components/common/side-navigation/side-navigation.component';
import { FieldSelectComponent } from './components/fields/field-select/field-select.component';
import { ShimmerComponent } from './components/misc/shimmer/shimmer.component';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { ModeSwitchComponent } from './components/misc/mode-switch/mode-switch.component';
import { PaginationComponent } from './components/misc/pagination/pagination.component';
import { FormComponent } from './components/common/paged-form/paged-form.component';
import { FieldHorizontalRadioComponent } from './components/fields/field-horizontal-radio/field-horizontal-radio.component';
import { FieldRadiosComponent } from './components/fields/field-radios/field-radios.component';
import { FieldTypeaheadComponent } from './components/fields/field-typeahead/field-typeahead.component';
import { FieldDateComponent } from './components/fields/field-date/field-date.component';
import { FieldCurrencyComponent } from './components/fields/field-currency/field-currency.component';
import { InlineSpinnerComponent } from './components/misc/inline-spinner/inline-spinner.component';
import { FieldCheckboxesComponent } from './components/fields/field-checkboxes/field-checkboxes.component';
import { FieldRichtextComponent } from './components/fields/field-richtext/field-richtext.component';
import { QuillModule } from 'ngx-quill';
import { FieldPasswordCheckComponent } from './components/fields/field-password-check/field-password-check.component';
import { PublicHeaderComponent } from './components/layout/header/public-header/public-header.component';
import { NgxDropzoneModule } from './components/common/ngx-dropzone/public_api';
import { FieldDropzoneComponent } from './components/fields/field-dropzone/field-dropzone.component';
import { FieldTimeComponent } from './components/fields/field-time/field-time.component';
import { CrmCtgDossiersComponent } from './pages/crm-ctg-dossiers/crm-ctg-dossiers.component';
import { CrmCtgDossierDetailComponent } from './components/modals/crm-ctg-dossier-detail/crm-ctg-dossier-detail.component';
import { FieldMultiInputComponent } from './components/fields/field-multi-input/field-multi-input.component';
import { FieldProductsComponent } from './components/fields/field-products/field-products.component';
import { FieldProductComponent } from './components/fields/field-product/field-product.component';
import { FieldIndicationsComponent } from './components/fields/field-indications/field-indications.component';
import { BulkUploadComponent } from './pages/bulk-upload/bulk-upload.component';
import { ConfirmActionComponent } from './components/misc/confirm-action/confirm-action.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { FieldDossiersComponent } from './components/fields/field-dossiers/field-dossiers.component';
import { FieldPharmaCompaniesComponent } from './components/fields/field-pharma-companies/field-pharma-companies.component';
import { FieldSamCompaniesComponent } from './components/fields/field-sam-companies/field-sam-companies.component';
import { NotulesComponent } from './pages/notules/notules.component';
import { ContractsComponent } from './pages/contracts/contracts.component';
import { ContractDetailComponent } from './components/modals/contract-detail/contract-detail.component';
import { ContractDossiersComponent } from './components/modals/contract-dossiers/contract-dossiers.component';
import { ContractVersionComponent } from './components/fields/contract-version/contract-version.component';
import { ContractProductsComponent } from './components/modals/contract-products/contract-products.component';
import { PriceDossiersComponent } from './pages/price-dossiers/price-dossiers.component';
import { PriceDossierDetailComponent } from './components/modals/price-dossier-detail/price-dossier-detail.component';
import { FieldContractComponent } from './components/fields/field-contract/field-contract.component';
import { DocumentsComponent } from './components/forms/documents/documents.component';
import { ModalDocumentContractOptionsComponent } from './components/modals/modal-document-contract-options/modal-document-contract-options.component';
import { ContractModalPaymentsCopyComponent } from './components/modals/contract-payments-copy/contract-payments-copy.component';
import { ContractModalEstimatedCopyComponent } from './components/modals/contract-estimated-copy/contract-estimated-copy.component';
import { AddContactPersonComponent } from './components/modals/add-contact-person/add-contact-person.component';
import { ModalAddIndicationComponent } from './components/modals/modal-add-indication/modal-add-indication.component';
import { AddUmbrellaContractComponent } from './components/modals/add-umbrella-contract/add-umbrella-contract.component';
import { FieldCompanyAllComponent } from './components/fields/field-company-all/field-company-all.component';
import { FieldContractsComponent } from './components/fields/field-contracts/field-contracts.component';
import { resourcePipe } from './services/resource.service';
import { InitService } from './services/init.service';
import { FieldMultiselectComponent } from './components/fields/field-multiselect/field-multiselect.component';
import { ApiInterceptor } from './utils/interceptors';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        SpinnerComponent,
        FieldTextComponent,
        FieldToggleComponent,
        FieldTextareaComponent,
        AvatarComponent,
        PopoutPanelComponent,
        FieldSwitchComponent,
        FilterButtonComponent,
        TableComponent,
        PopoverComponent,
        ClickOutsideDirective,
        formatTsPipe,
        displayPipe,
        booleanDisplayPipe,
        floatDisplayPipe,
        numberDisplayPipe,
        customCurrencyPipe,
        percentagePipe,
        TranslatePipe,
        ArrayPipe,
        callbackPipe,
        SafePipe,
        resourcePipe,
        FilterButtonGroupComponent,
        EmptyStateComponent,
        ModalSidePanelComponent,
        SideNavigationComponent,
        FieldSelectComponent,
        ShimmerComponent,
        ModeSwitchComponent,
        PaginationComponent,
        FormComponent,
        FieldHorizontalRadioComponent,
        FieldRadiosComponent,
        FieldTypeaheadComponent,
        FieldDateComponent,
        FieldCurrencyComponent,
        InlineSpinnerComponent,
        FieldCheckboxesComponent,
        FieldRichtextComponent,
        FieldPasswordCheckComponent,
        PublicHeaderComponent,
        FieldDropzoneComponent,
        FieldTimeComponent,
        CrmCtgDossiersComponent,
        CrmCtgDossierDetailComponent,
        FieldMultiInputComponent,
        FieldProductsComponent,
        FieldProductComponent,
        FieldIndicationsComponent,
        BulkUploadComponent,
        ConfirmActionComponent,
        CompaniesComponent,
        FieldDossiersComponent,
        FieldPharmaCompaniesComponent,
        FieldSamCompaniesComponent,
        NotulesComponent,
        ContractsComponent,
        ContractDetailComponent,
        ContractDossiersComponent,
        ContractVersionComponent,
        ContractProductsComponent,
        PriceDossiersComponent,
        PriceDossierDetailComponent,
        FieldContractComponent,
        DocumentsComponent,
        ModalDocumentContractOptionsComponent,
        ContractModalPaymentsCopyComponent,
        ContractModalEstimatedCopyComponent,
        AddContactPersonComponent,
        ModalAddIndicationComponent,
        AddUmbrellaContractComponent,
        FieldCompanyAllComponent,
        FieldContractsComponent,
        FieldMultiselectComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        QuillModule.forRoot(),
        NgxDropzoneModule,
        CommonModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            extendedTimeOut: 5000,
            positionClass: 'toast-bottom-right',
            closeButton: true,
            // disableTimeOut: true,
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning'
            }
        }),
        AppRoutingModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        ApiModule.forRoot(() => {
            return new Configuration({ basePath: '/api' });
        }),
        NgxMaskDirective,
        NgxMaskPipe
    ],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        provideAppInitializer(() => {
            const initializerFn = initializeApp(inject(InitService));
            return initializerFn();
        }),
        provideNgxMask(),
        CurrencyPipe,
        customCurrencyPipe,
        percentagePipe,
        TranslatePipe,
        provideHttpClient(withInterceptors([ApiInterceptor]))
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function initializeApp(InitService: InitService) {
    return (): Promise<any> => {
        return InitService.initialize();
    };
}
