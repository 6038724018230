import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, map } from 'rxjs/operators';
import { CrmCtgDossiersService } from 'src/app/services/crm-ctg-dossiers.service';
import { Dossier, Product } from 'src/app/utils/api';

@Component({
    selector: 'app-field-dossiers',
    templateUrl: './field-dossiers.component.html',
    styleUrls: ['./field-dossiers.component.scss'],
    standalone: false
})
export class FieldDossiersComponent implements OnInit {
    @Input() label?: string;
    @Input() error?: string | string[];
    @Input() placeholder?: string;
    @Input() model: any;
    @Input() selected: any = '';
    @Output() modelChange = new EventEmitter();
    @Output() enter = new EventEmitter();

    constructor(private dossierService: CrmCtgDossiersService) {}

    ngOnInit(): void {}

    formatter = (item: { key: any; value: string }) => item.value;

    public onFocus(e: Event): void {
        e.stopPropagation();
        setTimeout(() => {
            const inputEvent: Event = new Event('input');
            e.target.dispatchEvent(inputEvent);
        }, 0);
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter((term) => {
                return term.length > 1;
            }),
            switchMap((term) => this.dossierService.searchDossiers(term)),
            map((val) => {
                let dossiers = [];
                for (const dossier of val.data) {
                    dossiers.push({
                        key: dossier.id,
                        value: this.getDossierInfo(dossier),
                        dossier: dossier
                    });
                }

                if (dossiers.length == 0) {
                    dossiers.push({
                        key: '',
                        value: 'No dossiers found.',
                        dossier: null
                    });
                }
                return dossiers;
            })
        );

    onSelect($event) {
        if ($event.item.dossier) {
            const event = {
                dossier: $event.item.value,
                dossierId: $event.item.key,
                folderId: $event.item.dossier.folderId,
                companies: $event.item.dossier.companies
            };
            this.modelChange.emit(event);
        }
    }

    blur($event) {
        const selectedDossier = this.selected.value ? this.selected.value : this.selected;
        if (!selectedDossier || selectedDossier != this.model) {
            const event = {
                dossierId: null,
                folderId: null,
                companyName: null
            };
            this.modelChange.emit(event);
        }
    }

    getDossierInfo(dossier) {
        if (dossier.alias) {
            return dossier.alias;
        } else {
            return '[No dossier name]';
        }
    }

    getDossierNrs(dossier_nrs: string[]) {
        if (dossier_nrs && dossier_nrs.length > 1) {
            return dossier_nrs.slice(0, 1);
        } else {
            return dossier_nrs ? dossier_nrs : '';
        }
    }

    getStampDossierNrs(values: any[]) {
        if (values && values.length > 1) {
            return '+' + (values.length - 1);
        }
    }
}
