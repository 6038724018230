import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart } from '@angular/router';
import { Router } from '@angular/router';
import { Event as NavigationEvent } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FieldSwitchOptionsModel } from 'src/app/components/fields/field-switch/field-switch.component';
import { CompanyService } from 'src/app/services/company.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { TranslatePipe } from 'src/app/utils/pipes';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'app-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.scss'],
    standalone: false
})
export class CompaniesComponent implements OnInit, OnDestroy {
    onDestroy$: Subject<void> = new Subject<void>();
    searchSTR: any = {};
    switchOptions: FieldSwitchOptionsModel;
    statusOptions;
    exportBusy: boolean = false;

    tableHeads: any;
    tableSort: { code: string; dir: string };
    tableSortName = 'companies_tableSort';

    companies: any[] = null;
    originalCompanies: any[] = null;
    startRow: number = 0;
    RPP: number = 20;
    totalRows: number = null;
    tableLoading: boolean = true;
    searchType: string = 'advanced';

    constructor(
        private router: Router,
        private companyService: CompanyService,
        private translatePipe: TranslatePipe,
        private helpersService: HelpersService,
        private toastr: ToastrService,
        private localStorageService: LocalStorageService,
        private route: ActivatedRoute
    ) {
        // this.tableSort = this.getTableSort();
        // if (!this.tableSort) {
        //     this.tableSort = { code: 'companyEditTS', dir: 'desc' };
        // }
        // router.events
        //     .pipe(
        //         filter((event: NavigationEvent) => {
        //             return event instanceof NavigationStart;
        //         })
        //     )
        //     .subscribe((event: NavigationStart) => {
        //         if (event.restoredState) {
        //             window.location.href = event.url;
        //         }
        //     });
        // route.queryParams.subscribe((params) => {
        //     this.startRow = (params.page || 1) * this.RPP - this.RPP;
        //     this.searchSTR = params.query ? JSON.parse(params.query) : this.getDefaultSearchSTR();
        //     this.getCompanies();
        // });
    }

    ngOnInit(): void {
        // this.initSearchSTR();

        this.statusOptions = [
            { title: this.translatePipe.transform('companies_searchForm_status_all'), value: 'all' },
            { title: this.translatePipe.transform('companies_searchForm_status_linked'), value: 'linked' },
            { title: this.translatePipe.transform('companies_searchForm_status_open'), value: 'open' }
        ];

        this.switchOptions = [
            {
                title: this.translatePipe.transform('companies_searchForm_title_'),
                value: 'advanced'
            }
        ];

        this.tableHeads = [
            {
                name: this.translatePipe.transform('companies_table_companyName'),
                code: 'companyName',
                sortable: true,
                width: '30%'
            },
            {
                name: this.translatePipe.transform('companies_table_samCompanyName'),
                code: 'sam_companies',
                sortable: false,
                width: '30%'
            },
            {
                name: this.translatePipe.transform('companies_table_lastModifiedTS'),
                code: 'companyEditTS',
                sortable: true,
                width: '30%'
            },
            {
                name: '',
                code: 'edit'
            }
        ];

        // this.tableSort = this.getTableSort();
        // if (!this.tableSort) {
        //     this.tableSort = { code: 'companyEditTS', dir: 'desc' };
        // }

        // this.getCompanies();

        this.tableSort = this.getTableSort();
        if (!this.tableSort) {
            this.tableSort = { code: 'companyEditTS', dir: 'desc' };
        }
        this.router.events
            .pipe(
                filter((event: NavigationEvent) => {
                    return event instanceof NavigationStart;
                })
            )
            .subscribe((event: NavigationStart) => {
                if (event.restoredState) {
                    window.location.href = event.url;
                }
            });
        this.route.queryParams.subscribe((params) => {
            this.startRow = (params.page || 1) * this.RPP - this.RPP;
            this.searchSTR = params.query ? JSON.parse(params.query) : this.getDefaultSearchSTR();
            this.getCompanies();
        });
    }

    private getTableSort() {
        const tableSortLS = this.localStorageService.getItem(this.tableSortName);
        if (
            tableSortLS &&
            this.tableHeads.filter((item) => item.code == tableSortLS.code && item.sortable == true).length == 1
        ) {
            return tableSortLS;
        }

        return null;
    }

    getDefaultSearchSTR() {
        return { status: 'all' };
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    // initSearchSTR() {
    //     this.searchSTR = { status: 'all' };
    // }

    getSearchQuery() {
        return {
            ...this.searchSTR,
            startRow: this.startRow,
            RRP: this.RPP,
            orderBy: this.getSortARR()
        };
    }

    getSortARR() {
        if (!this.tableSort || !this.tableSort.code) {
            return [];
        }
        return [`${this.tableSort.code} ${this.tableSort.dir}`];
    }

    getSortingClass(head) {
        if (head.sortable && this.tableSort.code === head.code) {
            return this.tableSort.dir;
        } else if (head.sortable) {
            return 'none';
        }
    }

    clickSort(code) {
        let result = this.tableSort;
        if (this.tableSort.code === code) {
            result.dir = this.tableSort.dir === 'asc' ? 'desc' : 'asc';
        } else {
            result = { code: code, dir: 'asc' };
        }
        this.tableSort = { code: result.code, dir: result.dir };
        this.localStorageService.setItem(this.tableSortName, this.tableSort);
        this.getCompanies();
    }

    resetStartRow() {
        this.router.navigate([], {
            queryParams: {
                page: 1
            },
            queryParamsHandling: 'merge'
        });
    }

    changeStartRow(startRow: number) {
        this.router.navigate([], {
            queryParams: {
                page: Math.ceil(startRow / this.RPP) + 1
            },
            queryParamsHandling: 'merge'
        });
    }

    search(reset?: boolean) {
        this.startRow = 0;
        if (reset) {
            this.router.navigate([], {
                queryParams: {
                    query: null,
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        } else {
            this.router.navigate([], {
                queryParams: {
                    query: JSON.stringify(this.searchSTR),
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        }
    }

    export() {
        const searchSTR = this.getSearchQuery();
        let params = new HttpParams();
        for (const key in searchSTR) {
            params = params.append(key, searchSTR[key]);
        }
        this.exportBusy = true;
        this.companyService.exportCompanies(searchSTR).subscribe((data) => {
            setTimeout(() => {
                this.exportBusy = false;
            }, 500);
        });
    }

    getCompanies() {
        const searchSTR = this.getSearchQuery();
        this.tableLoading = true;
        this.companyService
            .searchLinkedCompanies(searchSTR)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((next) => {
                if (this.startRow && this.startRow >= next.rows) {
                    this.resetStartRow();
                    return;
                }
                this.companies = next.data;
                this.originalCompanies = JSON.parse(JSON.stringify(next.data));
                this.tableLoading = false;
                this.totalRows = next.rows;
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            });
    }

    reloadCompany(company_id) {
        for (const originalCompany of this.originalCompanies) {
            if (originalCompany.company_id == company_id) {
                for (const company of this.companies) {
                    if (company.company_id == originalCompany.company_id) {
                        company.sam_companies = originalCompany.sam_companies;
                        return;
                    }
                }
            }
        }
    }

    confirmChange(item) {
        this.companyService.linkCompanies(item).subscribe((result) => {
            item.companyEditTS = result.companyEditTS;
            item.edit = false;

            this.toastr.success(
                this.translatePipe.transform('companies_linked_confirmed'),
                this.translatePipe.transform('companies_linked_confirmed_title')
            );
        });
    }

    cancel(item) {
        item.edit = false;
        this.reloadCompany(item.company_id);
    }
}
