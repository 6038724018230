import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, HostListener } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UsersService } from 'src/app/services/users.service';
import { ResourceService } from 'src/app/services/resource.service';
import { TranslatePipe } from 'src/app/utils/pipes';
import { Document, Folder } from 'backend/src/models/document-model';
import { ToastrService } from 'ngx-toastr';
import { HelpersService } from 'src/app/services/helpers.service';
import * as moment from 'moment';
import 'moment/locale/nl-be';
import * as filesize from 'filesize';
import { ContractsService } from 'src/app/services/contracts.service';
import { allowedExtensions } from 'backend/src/models/contract-model';
import { Router } from '@angular/router';
import { DocumentService } from 'src/app/services/document.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ContractModalPaymentsCopyComponent } from '../contract-payments-copy/contract-payments-copy.component';
import { ContractModalEstimatedCopyComponent } from '../contract-estimated-copy/contract-estimated-copy.component';
import { AddUmbrellaContractComponent } from '../add-umbrella-contract/add-umbrella-contract.component';
import { Contract, DefaultService, Estimated } from 'src/app/utils/api';

moment.locale('nl-be');

interface SummaryFormSTR {
    id: string;
    contract_questions: string;
    prolongation?: boolean;
    transition_year?: boolean;
    post_contract?: string;
    facial_price_decrease_post_contract?: number;
    post_contract_comment?: string;
    stage?: string;
}

@Component({
    selector: 'app-contract-detail',
    templateUrl: './contract-detail.component.html',
    styleUrls: ['./contract-detail.component.scss'],
    standalone: false
})
export class ContractDetailComponent implements OnInit, OnDestroy {
    @Input() id?: string;
    @Input() mode?: string;
    @Input() activePage?: string = 'summary';
    @Output() created = new EventEmitter();
    @Output() updated = new EventEmitter();

    contract: any = null;
    parentContract: any = null;
    latestAmendment: any = null;
    allContractProducts: any[] = null;
    folders: any = null;

    formSTR: any = {};
    financialSchemeFormSTR: any = {};
    financialScheme: any = {};
    paymentFormSTR: any = {};
    estimatedFormSTR: any = {};
    summaryFormSTR: SummaryFormSTR = null;

    editAmendment: string = null;

    ready: boolean = false;
    loading: boolean = false;
    saving: boolean = false;
    loadingVersion: boolean = false;

    pages: {
        code: any;
        name: any;
        notify?: boolean;
        icon?: string;
        disabled?: boolean;
        indent?: boolean;
        customContent?: boolean;
    }[];
    form: any;
    popoverHelper: any = null;
    validation: any = {};
    changes: boolean = false;
    helper: any = {
        version: null,
        copyFinancialSchemeData: false
    };
    paymentActions: any[] = [];
    estimatedActions: any[] = [];
    advancePayment: any;
    yesNoOptions;
    collegeOrphanDrugsOptions: any[] = [];
    conventionTypeOptions: any[] = [];
    registryOptions: any[] = [];
    typeOfArt15Options: any[] = [];
    contractDateTypeOptions: any[] = [];
    contractTypeOptions: any[] = [];
    advisorOptions: any[] = [];
    stageOptions: any[] = [];
    procedureArticleOptions: any[] = [];
    procedureArticleAmendmentOptions: any[] = [];

    options: any = {};
    optionsPayments: any = {};
    optionsEstimated: any = {};

    //Documents
    documentsReady: boolean = false;

    typeaheadModels: any = {};

    onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private contractsService: ContractsService,
        private documentService: DocumentService,
        private usersService: UsersService,
        private resourceService: ResourceService,
        private translatePipe: TranslatePipe,
        private helpersService: HelpersService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private ModalService: NgbModal,
        private DefaultService: DefaultService
    ) {}

    ngOnInit(): void {
        if (this.id) {
            // EDIT
            this.getContract();
        } else {
            // CREATE
            this.contract = {
                contract_type: 'new',
                convention_type: [],
                contract_date_type: 'initial',
                post_contract: 'not_yet',
                name: 'Initial version'
            };
            this.initPages(false);
            this.resetForm();
            this.parentContract = {};
            this.latestAmendment = {};
            this.mode = 'create';
            this.initOverviewResources();
            this.ready = true;
        }

        this.yesNoOptions = [
            { title: this.translatePipe.transform('contract_detail_yes'), value: true },
            { title: this.translatePipe.transform('contract_detail_no'), value: false }
        ];

        // this.initActivePage(this.activePage, this.mode);
        this.initOptions();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    initPages(notify) {
        this.pages = [
            {
                code: 'summary',
                name: this.translatePipe.transform('contract_detail_menu_summary'),
                icon: 'summary-list'
            },
            {
                code: 'estimated',
                name: this.translatePipe.transform('contract_detail_menu_estimated'),
                icon: 'receipt-euro'
            },
            {
                code: 'amendments',
                name: 'amendments',
                customContent: true,
                indent: true
            },
            {
                code: 'overview',
                name: this.translatePipe.transform('contract_detail_menu_overview'),
                icon: 'document',
                indent: true
            },
            {
                code: 'financial',
                name: this.translatePipe.transform('contract_detail_menu_financial'),
                icon: 'stats',
                indent: true
            },
            {
                code: 'payments',
                name: this.translatePipe.transform('contract_detail_menu_payments'),
                icon: 'budget',
                indent: true
            },
            {
                code: 'documents',
                name: this.translatePipe.transform('contract_detail_menu_documents'),
                icon: 'folder',
                notify: notify,
                indent: true
            }
        ];
    }

    initActivePage(activePage, mode?) {
        switch (activePage) {
            case 'summary':
                this.initSummary(mode);
                break;
            case 'estimated':
                this.initEstimated(mode);
                break;
            case 'overview':
                this.initOverview(mode);
                break;
            case 'financial':
                this.initFinancial(mode);
                break;
            case 'payments':
                this.initPayments(mode);
                break;
            case 'documents':
                this.initDocuments();
                break;
            default:
                this.activePage = this.id ? 'summary' : 'overview';
                this.helpersService.addParam('activePage', this.activePage);
                this.initOverview(mode);
                return;
        }
        this.helpersService.addParam('activePage', activePage);
    }

    initSummary(mode) {
        if (mode) {
            this.mode = mode;
        } else {
            if (this.id) {
                this.mode = 'show';
            } else {
                this.mode = 'create';
            }
        }

        this.activePage = 'summary';
        this.initOverviewResources();
    }

    initEstimated(mode) {
        // this.loading = true;
        if (mode) {
            this.mode = mode;
        } else {
            this.mode = 'show';
        }
        this.activePage = 'estimated';
        this.estimatedActions = [
            {
                name: this.translatePipe.transform('contract_detail_table_edit'),
                code: 'edit',
                icon: 'pencil'
            },
            {
                name: this.translatePipe.transform('contract_detail_table_copy'),
                code: 'copy',
                icon: 'pencil'
            }
        ];

        if (this.authenticationService.hasAccessDeleteContracts()) {
            this.estimatedActions.push({
                name: this.translatePipe.transform('contract_table_delete'),
                code: 'delete',
                icon: 'trash',
                class: 'delete-red',
                confirm: true,
                confirm_type: 'delete'
            });
        }
    }

    getEstimatedMonths() {
        const months = [];
        this.parentContract.estimated.forEach((item) => {
            const date = new Date(item.year, item.month - 1, 1);
            const monthyear = moment(date).format('YYYY-MM');
            if (!this.parentContract.estimated.length || months.indexOf(monthyear) == -1) {
                months.push(monthyear);
            }
        });
        return months;
    }

    initOverview(mode) {
        if (mode) {
            this.mode = mode;
        } else {
            if (this.id) {
                this.mode = 'show';
            } else {
                this.mode = 'create';
            }
        }

        this.activePage = 'overview';
        this.initOverviewResources();
    }

    initOverviewResources() {
        this.contractTypeOptions = this.resourceService.getResourceByType('contract_type').map((item) => {
            return { title: item.value, value: item.key };
        });
        this.getAdvisors();
        this.collegeOrphanDrugsOptions = this.resourceService.getResourceByType('college_orphan_drugs').map((item) => {
            return { title: item.value, value: item.key };
        });
        this.registryOptions = this.resourceService.getResourceByType('college_orphan_drugs').map((item) => {
            return { title: item.value, value: item.key };
        });
        this.conventionTypeOptions = this.resourceService.getResourceByType('convention_type').map((item) => {
            return { title: item.value, value: item.key };
        });
        this.typeOfArt15Options = this.resourceService.getResourceByType('type_of_art_15');
        this.contractDateTypeOptions = this.resourceService.getResourceByType('contract_date_type');
        this.stageOptions = this.resourceService.getResourceByType('stage');
        this.procedureArticleOptions = this.resourceService.getResourceByType('procedure_article').map((item) => {
            return { title: item.value, value: item.key };
        });
        this.procedureArticleAmendmentOptions = this.resourceService
            .getResourceByType('procedure_article_amendment')
            .map((item) => {
                return { title: item.value, value: item.key };
            });
    }

    initOptions() {
        this.options = {
            amendment_type: this.initOption(this.resourceService.getResourceByType('amendment_type')),
            payments_actual_estimate: this.initOption(
                this.resourceService.getResourceByType('payments_actual_estimate')
            ),
            financial_scheme_tier_range: this.initOption(
                this.resourceService.getResourceByType('financial_scheme_tier_range')
            ),
            financial_scheme_refund_type: this.initOption(
                this.resourceService.getResourceByType('financial_scheme_refund_type').map((item) => {
                    return { title: item.value, value: item.key };
                })
            ),
            financial_scheme_payment_date: this.initOption(
                this.resourceService.getResourceByType('financial_scheme_payment_date')
            ),
            financial_scheme_type: this.initOption(this.resourceService.getResourceByType('financial_scheme_type')),
            post_contract: this.initOption(this.resourceService.getResourceByType('post_contract')),
            crm_to_wg: this.initOption(this.resourceService.getResourceByType('crm_to_wg')),
            umbrella_contract: this.initOption(this.resourceService.getResourceByType('umbrella_contract'))
        };
        this.options?.umbrella_contract?.push({
            value: this.translatePipe.transform('contractdetail_add_new_umbrella_contract_option'),
            key: 'new'
        });
    }

    UmbrellaContractChange($event) {
        if ($event == 'new') {
            setTimeout(() => {
                this.formSTR.umbrella_contract = undefined;
            }, 1);
            const modalRef = this.ModalService.open(AddUmbrellaContractComponent, {
                windowClass: 'main-modal options-modal',
                beforeDismiss: () => {
                    return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
                }
            });

            modalRef.componentInstance.confirmed.subscribe((next) => {
                const uuid = this.helpersService.getUUID();
                this.options.umbrella_contract = [
                    { key: uuid, value: next, new: true },
                    ...this.options.umbrella_contract
                ];
                this.formSTR.umbrella_contract = uuid;
                this.formChange();
            });
        }
    }

    initOption(arr) {
        if (
            arr &&
            !arr.find((item) => {
                return item.is_default;
            })
        ) {
            arr[0].is_default = true;
        }
        return arr;
    }

    createUpdateAmendment(id?: string) {
        this.pageChange('overview');
        this.mode = 'edit';
        this.editAmendment = id || 'new';

        if (this.editAmendment == 'new') {
            this.formSTR.id = '';
            this.formSTR.name = '';
            this.formSTR.start_date = null;
            this.formSTR.end_date = null;
            this.formSTR.key_changes = '';
            this.formSTR.procedure_article = null;
            this.formSTR.type_of_art_15 = undefined;
            this.formSTR.rational_modalities = '';
            // this.formSTR.financial_modalities = '';
            this.formSTR.convention_type = [];
            this.formSTR.dossiers = [];
            this.formSTR.comment = '';
            this.formSTR.contract_type = this.options.amendment_type.find((item) => {
                return item.is_default;
            }).key;
        }
        this.setDateLength(this.formSTR);
    }

    clickEdit() {
        if (this.contract.lnk_contract_uuid) {
            this.createUpdateAmendment(this.id);
        } else this.mode = 'edit';
    }

    resetAmendmentProps() {
        // this.formSTR.contract_type = this.contract.contract_type;
        // this.formSTR.name = this.contract.name;
        this.editAmendment = null;
        this.resetForm();
        this.setDateLength(this.formSTR);
    }

    // financial scheme
    initFinancial(mode) {
        if (mode) {
            this.mode = mode;
        } else {
            this.mode = 'show';
        }
        this.activePage = 'financial';
    }

    initCreateUpdateSummary() {
        this.summaryFormSTR = {
            id: this.parentContract.id,
            contract_questions: this.parentContract.contract_questions,
            prolongation: this.parentContract.prolongation,
            transition_year: this.parentContract.transition_year,
            post_contract: this.parentContract.post_contract,
            facial_price_decrease_post_contract: this.parentContract.facial_price_decrease_post_contract,
            post_contract_comment: this.parentContract.post_contract_comment,
            stage: this.parentContract.stage
        };
        this.validation = {};
        this.mode = 'edit';
        // setTimeout(() => {
        //     const objDiv = document.querySelector('.detail-modal');
        //     objDiv.scrollTo({ top: objDiv.scrollHeight, behavior: 'smooth' });
        // }, 200);
    }

    initCreateUpdateFinancialScheme(scheme?: any) {
        this.mode = 'createUpdate';
        this.validation = {};
        this.helper.copyFinancialSchemeData = false;
        const startDate = this.contract.start_date;
        const endDate = this.contract.end_date;
        if (!scheme) {
            this.financialSchemeFormSTR = {
                name: null,
                type: 'pvc',
                comments: null,
                lnk_contract_uuid: this.id
            };
            // initial periods
            this.financialSchemeFormSTR.periods = [];
            let currStartDate = startDate;
            let currEndDate = moment(startDate).add(1, 'years').subtract(1, 'days').format('YYYY-MM-DD');
            while (moment(currStartDate).isSameOrBefore(endDate)) {
                const period = {
                    start_date: currStartDate,
                    end_date: currEndDate,
                    estimated_sales: null,
                    comment: null,
                    refund_mechanism_umbrella: null,
                    caps: null,
                    expected_average_refund: null,
                    payment_dates: [],
                    refund_type: this.options.financial_scheme_refund_type.find((item) => {
                        return item.is_default;
                    })?.key,
                    refunds: []
                };
                this.financialSchemeFormSTR.periods.push(period);
                currStartDate = moment(currStartDate).add(1, 'years').format('YYYY-MM-DD');
                currEndDate = moment(currStartDate).add(1, 'years').subtract(1, 'days').format('YYYY-MM-DD');
            }
            // initial refund periods on period
            for (let i = 0; i < this.financialSchemeFormSTR.periods.length; i++) {
                const period = this.financialSchemeFormSTR.periods[i];
                period.refunds.push({
                    type: 'tier',
                    range: this.options.financial_scheme_tier_range.find((item) => {
                        return item.is_default;
                    })?.key,
                    amount: null,
                    amount_end: null,
                    refund_percentage: null
                });
            }
        } else {
            this.mode = 'view';
            this.helper.finDetail = true;
            this.financialSchemeFormSTR = {
                ...scheme,
                periods: scheme.periods.map((item) => {
                    item.collapsed = true;
                    return item;
                })
            };
            this.financialScheme = {
                ...scheme,
                periods: scheme.periods.map((item) => {
                    item.collapsed = true;
                    return item;
                })
            };
        }
        this.getContract();
    }

    // getPeriodValidation(periodIndex, code) {}

    // getPaymentDateValidation(periodIndex, paymentIndex, code) {}

    // getRefundValidation(periodIndex, refundIndex, code) {}

    deletePeriod() {
        this.formChange();
        this.financialSchemeFormSTR.periods.splice(this.financialSchemeFormSTR.periods.length - 1, 1);
    }

    deletePaymentDate(periodIndex, paymentIndex) {
        this.formChange();
        this.financialSchemeFormSTR.periods[periodIndex].payment_dates.splice(paymentIndex, 1);
    }

    deleteRefund(periodIndex, refundIndex) {
        this.formChange();
        this.financialSchemeFormSTR.periods[periodIndex].refunds.splice(refundIndex, 1);
    }

    addRefund(periodIndex, type) {
        this.formChange();
        const period: any = {
            type: type,
            amount: null
        };
        if (type === 'tier') {
            period.range = this.options.financial_scheme_tier_range.find((item) => {
                return item.is_default;
            })?.key;
            period.amount_end = null;
            period.refund_percentage = null;
            const lastTierOccurance = this.financialSchemeFormSTR.periods[periodIndex].refunds
                .map((item) => {
                    return item.type;
                })
                .lastIndexOf('tier');
            if (lastTierOccurance == -1) {
                this.financialSchemeFormSTR.periods[periodIndex].refunds.splice(0, 0, period);
            } else {
                this.financialSchemeFormSTR.periods[periodIndex].refunds.splice(lastTierOccurance + 1, 0, period);
            }
        } else this.financialSchemeFormSTR.periods[periodIndex].refunds.push(period);
        this.financialSchemeFormSTR.periods[periodIndex].collapsed = false;
    }

    addPeriod() {
        this.formChange();
        const lastEndDate = this.financialSchemeFormSTR.periods.length
            ? this.financialSchemeFormSTR.periods[this.financialSchemeFormSTR.periods.length - 1].end_date
            : moment(this.contract.start_date).subtract(1, 'days').format('YYYY-MM-DD');
        const startDate = moment(lastEndDate).add(1, 'days');
        const endDate = moment(startDate).add(1, 'years').subtract(1, 'days');
        const paymentDates = [];
        if (this.financialSchemeFormSTR?.periods[0]?.payment_dates?.length) {
            this.financialSchemeFormSTR?.periods[0]?.payment_dates?.forEach(() => {
                paymentDates.push({
                    type: this.options.financial_scheme_payment_date.find((item) => {
                        return item.is_default;
                    })?.key,
                    date: null
                });
            });
        }
        const period = {
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD'),
            estimated_sales: null,
            comment: null,
            refund_mechanism_umbrella: null,
            caps: null,
            expected_average_refund: null,
            payment_dates: paymentDates,
            refund_type: this.options.financial_scheme_refund_type.find((item) => {
                return item.is_default;
            })?.key,
            refunds: [
                {
                    type: 'tier',
                    range: this.options.financial_scheme_tier_range.find((item) => {
                        return item.is_default;
                    })?.key,
                    amount: null,
                    amount_end: null,
                    refund_percentage: null
                }
            ]
        };
        if (this.helper.copyFinancialSchemeData && this.financialSchemeFormSTR.periods.length) {
            const prev = this.financialSchemeFormSTR.periods[this.financialSchemeFormSTR.periods.length - 1];
            period.estimated_sales = prev.estimated_sales;
            period.comment = prev.comment;
            period.refund_mechanism_umbrella = prev.refund_mechanism_umbrella;
            period.caps = prev.caps;
            period.expected_average_refund = prev.expected_average_refund;
            period.refund_type = prev.refund_type;
            period.refunds = prev.refunds;
        }
        this.financialSchemeFormSTR.periods.push(period);
    }

    addPaymentDate(periodIndex) {
        this.formChange();
        this.financialSchemeFormSTR.periods[periodIndex].payment_dates.push({
            type: this.options.financial_scheme_payment_date.find((item) => {
                return item.is_default;
            })?.key,
            date: null
        });
    }

    // payments
    initPayments(mode) {
        if (mode) {
            this.mode = mode;
        } else {
            this.mode = 'show';
        }
        this.activePage = 'payments';
        this.paymentActions = [
            {
                name: this.translatePipe.transform('contract_detail_table_edit'),
                code: 'edit',
                icon: 'pencil'
            },
            {
                name: this.translatePipe.transform('contract_detail_table_copy'),
                code: 'copy',
                icon: 'pencil'
            }
        ];

        if (this.authenticationService.hasAccessDeleteContracts()) {
            this.paymentActions.push({
                name: this.translatePipe.transform('contract_table_delete'),
                code: 'delete',
                icon: 'trash',
                class: 'delete-red',
                confirm: true,
                confirm_type: 'delete'
            });
        }
    }

    getPaymentMonths() {
        const months = [];
        this.contract.payments.forEach((item) => {
            const date = new Date(item.year, item.month - 1, 1);
            const monthyear = moment(date).format('YYYY-MM');
            if (!this.contract.payments.length || months.indexOf(monthyear) == -1) {
                months.push(monthyear);
            }
        });
        return months;
    }

    filterPaymentMonth = (option: any, monthYear: any) => {
        const date = new Date(option.year, option.month - 1, 1);
        return moment(date).format('YYYY-MM') == monthYear;
    };

    initCreateUpdatePayment(payment?: string) {
        this.mode = 'createUpdate';
        this.validation = {};
        this.initPaymentOptions();
        this.advancePayment = undefined;
        if (!payment) {
            this.paymentFormSTR = {
                lnk_financial_scheme_uuid:
                    this.contract?.financial_schemes.length == 1 ? this.contract.financial_schemes[0].uuid : undefined,
                lnk_contract_uuid: this.id
            };
            if (this.paymentFormSTR.lnk_financial_scheme_uuid) {
                this.setPeriodOptions();
            }
        } else {
            this.paymentFormSTR = payment;
            if (this.paymentFormSTR.lnk_financial_scheme_uuid) {
                this.setPeriodOptions();
            }
            if (this.paymentFormSTR.lnk_period_uuid) {
                this.setPaymentDateOptions();
                this.setAdvancePrice();
            }
        }
    }

    initCreateUpdateEstimated(estimated?: string) {
        this.mode = 'createUpdate';
        this.validation = {};
        this.initEstimatedOptions();

        if (!estimated) {
            this.estimatedFormSTR = {
                lnk_contract_uuid: this.contract.lnk_contract_uuid || this.contract.id
            };
        } else {
            this.estimatedFormSTR = estimated;
        }
    }

    initEstimatedOptions() {
        this.optionsEstimated = {
            month: [
                { value: 'January', key: '1' },
                { value: 'February', key: '2' },
                { value: 'March', key: '3' },
                { value: 'April', key: '4' },
                { value: 'May', key: '5' },
                { value: 'June', key: '6' },
                { value: 'July', key: '7' },
                { value: 'August', key: '8' },
                { value: 'September', key: '9' },
                { value: 'October', key: '10' },
                { value: 'November', key: '11' },
                { value: 'December', key: '12' }
            ],
            year: this.getMinAndMaxYearFromFinancialScheme('estimated')
        };
    }

    initPaymentOptions() {
        this.optionsPayments = {
            month: [
                { value: 'January', key: '1' },
                { value: 'February', key: '2' },
                { value: 'March', key: '3' },
                { value: 'April', key: '4' },
                { value: 'May', key: '5' },
                { value: 'June', key: '6' },
                { value: 'July', key: '7' },
                { value: 'August', key: '8' },
                { value: 'September', key: '9' },
                { value: 'October', key: '10' },
                { value: 'November', key: '11' },
                { value: 'December', key: '12' }
            ],
            year: this.getMinAndMaxYearFromFinancialScheme(),
            actual_estimate: this.initOption(
                this.resourceService.getResourceByType('payments_actual_estimate').map((item) => {
                    return { title: item.value, value: item.key };
                })
            ),
            financial_schemes: this.contract.financial_schemes.map((item) => {
                return { value: item.name, key: item.uuid };
            }),
            periods: [],
            payment_dates: []
        };
    }

    deletePayment(uuid: string) {
        this.dismissPopover();
        this.contractsService.deletePayment(uuid).subscribe((next) => {
            this.getContract();
        });
    }

    InitYesNoOptionsForSelect() {
        return [
            { value: 'Yes', key: true },
            { value: 'No', key: false }
        ];
    }

    InitYesNoOptions() {
        return [
            { title: 'Yes', value: true },
            { title: 'No', value: false }
        ];
    }

    initDocuments() {
        this.getDocuments();
    }

    getMinAndMaxYearFromFinancialScheme(code?: 'estimated') {
        const result = [];
        let source = this.contract;
        switch (code) {
            case 'estimated':
                source = this.parentContract;
                break;
        }
        source.financial_schemes.forEach((item) => {
            item.periods.map((period) => {
                const startYear = moment(period.start_date).format('YYYY');
                const endYear = moment(period.end_date).format('YYYY');

                if (
                    !result.some((item) => {
                        return item.value == startYear;
                    })
                ) {
                    result.push({ value: startYear, key: startYear });
                }
                if (
                    !result.some((item) => {
                        return item.value == endYear;
                    })
                ) {
                    result.push({ value: endYear, key: endYear });
                }
            });
        });
        return result;
    }

    setPeriodOptions() {
        this.optionsPayments.periods = this.contract.financial_schemes
            .find((item) => {
                return item.uuid == this.paymentFormSTR.lnk_financial_scheme_uuid;
            })
            ?.periods.map((item, index) => {
                return {
                    value:
                        'Period ' +
                        (index + 1) +
                        ' (' +
                        moment(item.start_date).format('DD MMM YYYY') +
                        ' - ' +
                        moment(item.end_date).format('DD MMM YYYY') +
                        ')',
                    key: item.uuid
                };
            });
    }

    setAdvancePrice() {
        this.advancePayment = this.contract.payments.find((item) => {
            return item.lnk_period_uuid == this.paymentFormSTR.lnk_period_uuid && item.type == 'Advance';
        })?.refund;
    }

    setPaymentDateOptions() {
        this.optionsPayments.payment_dates = this.contract.financial_schemes
            .find((item) => {
                return item.uuid == this.paymentFormSTR.lnk_financial_scheme_uuid;
            })
            .periods.find((item) => {
                return item.uuid == this.paymentFormSTR.lnk_period_uuid;
            })
            ?.payment_dates.map((item) => {
                return {
                    value:
                        this.resourceService.getResourceTitleByTypeAndKey('financial_scheme_payment_date', item.type) +
                        ' - ' +
                        moment(item.date).format('DD MMM YYYY'),
                    key: item.uuid
                };
            });
    }

    openCopyPaymentsModal(lnk_payment_uuid?, monthYear?) {
        const modalRef = this.ModalService.open(ContractModalPaymentsCopyComponent, {
            windowClass: 'main-modal options-modal',
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
            }
        });
        this.initPaymentOptions();
        modalRef.componentInstance.options = this.optionsPayments;
        modalRef.componentInstance.multiple = monthYear ? true : false;
        modalRef.componentInstance.confirmed.subscribe((next) => {
            const form = {
                ...next.formSTR,
                lnk_payment_uuid: lnk_payment_uuid,
                lnk_contract_uuid: this.contract.id,
                copyFromMonthYear: monthYear
            };
            this.contractsService.copyPayments(form).subscribe((next) => {
                this.getContract();
            });
        });
    }

    copyPayment(uuid: string) {}

    resetLnkPeriodUuid() {
        this.paymentFormSTR.lnk_period_uuid = undefined;
        this.advancePayment = undefined;
    }

    resetPaymentDateUuid() {
        this.paymentFormSTR.lnk_payment_date_uuid = undefined;
    }

    getDocuments() {
        this.documentsReady = false;
        this.documentService.getContractDocuments(this.id).subscribe((next) => {
            this.folders = next;
            this.documentsReady = true;
        });
    }

    pageChange($event) {
        this.resetAmendmentProps();
        if ($event === 'financial') {
            this.mode = 'show';
            this.helper.finDetail = false;
        }
        if ((this.activePage === $event && !this.editAmendment) || !this.canExit()) return;
        this.changes = false;
        this.activePage = $event;
        this.initActivePage($event);
    }

    getActivePage() {
        if (this.activePage && this.pages) {
            return this.pages.find((item) => {
                return item.code == this.activePage;
            });
        }
    }

    getDateLengthSum() {
        let months = 0;

        if (this.contract?.versions?.length) {
            const endDate = this.contract.versions[0].end_date;
            const startDate = this.contract.versions[this.contract.versions.length - 1].start_date;

            months = this.getDateLength(startDate, endDate, true);
        }
        return `${months} month${months !== 1 ? 's' : ''}`;
    }

    getDateLength(start_date, end_date, number?: boolean): any {
        if (start_date && end_date) {
            // show in months

            let startdateMoment = moment(start_date);
            let enddateMoment = moment(end_date).add(1, 'days');

            if (startdateMoment.isValid() === true && enddateMoment.isValid() === true) {
                let months = enddateMoment.diff(startdateMoment, 'months');
                return number ? months : `${months} month${months !== 1 ? 's' : ''}`;
            } else return number ? 0 : '';

            // show in days, months, years

            // let startdateMoment = moment(start_date);
            // let enddateMoment = moment(end_date).add(1, 'days');

            // if (startdateMoment.isValid() === true && enddateMoment.isValid() === true) {
            //     let years = enddateMoment.diff(startdateMoment, 'years');
            //     let months = enddateMoment.diff(startdateMoment, 'months') - years * 12;
            //     startdateMoment.add(years, 'years').add(months, 'months');
            //     let days = enddateMoment.diff(startdateMoment, 'days');

            //     let result = '';

            //     if (years > 1) {
            //         result += years + ' years ';
            //     } else if (years == 1) {
            //         result += years + ' year ';
            //     }

            //     if (months > 1) {
            //         result += months + ' months ';
            //     } else if (months == 1) {
            //         result += months + ' month ';
            //     }

            //     if (days > 1) {
            //         result += days + ' days ';
            //     } else if (days == 1) {
            //         result += days + ' day ';
            //     }
            //     return result;
            // }
        } else return number ? 0 : '';
    }

    setDateLength(contract) {
        contract.date_length = '';
        if (contract.start_date && contract.end_date) {
            // show in months
            let startdateMoment = moment(contract.start_date);
            let enddateMoment = moment(contract.end_date).add(1, 'days');

            if (startdateMoment.isValid() === true && enddateMoment.isValid() === true) {
                let months = enddateMoment.diff(startdateMoment, 'months');
                contract.date_length = `${months} month${months !== 1 ? 's' : ''}`;
            } else contract.date_length = '';
        } else contract.date_length = '';
    }

    setCompany(contract) {
        if (contract.dossiers && contract.dossiers.length > 0) {
            contract.company_name = contract.dossiers[0].company_name;
            contract.lnk_company_id = contract.dossiers[0].company_id;
        } else {
            contract.company_name = undefined;
            contract.lnk_company_id = undefined;
        }
    }

    setContractAdvisor(contract) {
        if (
            !this.formSTR.advisor &&
            contract.dossiers &&
            contract.dossiers.length &&
            contract.dossiers[0].crm_advisor_id
        ) {
            this.formSTR.lnk_advisor_id = contract.dossiers[0].crm_advisor_id;
        }
    }

    setIndications(contract) {
        if (contract.dossiers && contract.dossiers.length > 0) {
            if (!contract.indications) contract.indications = [];
            for (const dossier of contract.dossiers) {
                const indications = dossier.indications;
                if (indications && indications.length > 0) {
                    for (const indication of indications) {
                        if (contract.indications.filter((e) => e.id === indication.id).length == 0) {
                            contract.indications.push(indication);
                        }
                    }
                }
            }
        }
    }

    getContract(versionId?: string, pageCode?: string) {
        if (versionId) this.id = versionId;
        this.contractsService
            .getContract(this.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (next: any) => {
                    if (next.lnk_renewal_contract_uuid) {
                        this.typeaheadModels.renewal_contract = {
                            key: next.lnk_renewal_contract_uuid,
                            value: next.renewal_contract_number
                        };
                    }

                    // if (this.id) {
                    //     this.activePage = 'summary';
                    // }

                    this.contract = this.formatContract(next);

                    this.contract.versions = this.contract.versions.map((item) => {
                        return {
                            ...item,
                            displayName: item.lnk_contract_uuid
                                ? item.name ||
                                  this.getContractName() ||
                                  this.translatePipe.transform('contractdetail_initial_version')
                                : this.getContractName() ||
                                  this.translatePipe.transform('contractdetail_initial_version')
                        };
                    });
                    this.helper.version = next.id;
                    this.initActivePage(pageCode || this.activePage, this.mode);
                    this.setDateLength(this.contract);
                    this.initPages(this.contract.newDocument);

                    if (this.pages) {
                        this.pages.map((item) => {
                            if (item.code == 'payments' || item.code == 'financial' || item.code == 'estimated') {
                                item.disabled = !next?.start_date || !next?.end_date ? true : false;
                            }
                            return item;
                        });
                    }

                    if (!next.lnk_contract_uuid) {
                        this.parentContract = this.formatContract(next);
                        this.getLatestAmendment();
                    } else {
                        this.contractsService.getContract(next.lnk_contract_uuid).subscribe((next) => {
                            this.parentContract = this.formatContract(next);
                            this.getLatestAmendment();
                        });
                    }

                    this.resetForm();
                },
                error: (error) => this.handleError(error)
            });
    }

    getLatestAmendment() {
        const id = this.parentContract.versions[0].id;
        if (this.parentContract.id === id) {
            this.latestAmendment = JSON.parse(JSON.stringify(this.parentContract));
            this.setAllContractProducts();
            this.ready = true;
            this.saving = false;
            this.loadingVersion = false;
        } else if (this.contract.id === id) {
            this.latestAmendment = JSON.parse(JSON.stringify(this.contract));
            this.setAllContractProducts();
            this.ready = true;
            this.saving = false;
            this.loadingVersion = false;
        } else {
            this.contractsService.getContract(id).subscribe((next) => {
                this.latestAmendment = this.formatContract(next);
                this.setAllContractProducts();
                this.ready = true;
                this.saving = false;
                this.loadingVersion = false;
            });
        }
    }

    setAllContractProducts() {
        const result = [];
        for (let i = 0; i < this.contract.versions.length; i++) {
            const version = this.contract.versions[i];
            const products = version.products || [];
            result.push(...products);
        }
        this.allContractProducts = result;
    }

    formatContract(contract) {
        let finschemes = [];
        if (contract?.financial_schemes?.length) {
            finschemes = contract.financial_schemes.map((item) => {
                let lnkLinkedContract = {};
                if (item.lnk_linked_contract_uuid) {
                    lnkLinkedContract = {
                        key: item.lnk_linked_contract_uuid,
                        value: item.linked_contract_number
                    };
                }
                return {
                    ...item,
                    displayType: this.resourceService.getResourceTitleByTypeAndKey('financial_scheme_type', item.type),
                    lnkContractTypeaheadModel: lnkLinkedContract
                };
            });
        }

        let payments = [];
        if (contract?.payments?.length) {
            payments = contract.payments.map((item) => {
                return {
                    ...item,
                    display_actual_estimate: this.resourceService.getResourceTitleByTypeAndKey(
                        'payments_actual_estimate',
                        item.actual_estimate
                    ),
                    period:
                        contract.financial_schemes
                            .find((subItem) => {
                                return subItem.uuid == item.lnk_financial_scheme_uuid;
                            })
                            .periods.findIndex((subItem) => {
                                return subItem.uuid == item.lnk_period_uuid;
                            }) + 1,
                    type: this.resourceService.getResourceTitleByTypeAndKey('financial_scheme_payment_date', item.type)
                };
            });
        }

        if (contract.umbrella_contract === '') {
            delete contract.umbrella_contract;
        }

        let related_contracts = [];
        if (contract.related_contracts) {
            related_contracts = contract.related_contracts.map((item) => {
                const products = item?.products?.map((item) => {
                    return item.product;
                });
                const type = item.lnk_contract_uuid
                    ? this.resourceService.getResourceTitleByTypeAndKey('amendment_type', item.contract_type)
                    : this.resourceService.getResourceTitleByTypeAndKey('contract_type', item.contract_type);
                return {
                    title: `${item.number ? item.number : 'No number - '} ${item.name ? item.name : 'No name'} - ${
                        type ? type : 'No type'
                    }`,
                    subtitle: products?.length ? `products: ${this.getSubTitle(products, 1)}` : ``,
                    tooltip: this.getTooltipValue(products, 1),
                    stamp: this.getStampForTootltip(products, 1),
                    contract: item
                };
            });
        }

        return {
            ...contract,
            financial_schemes: finschemes,
            payments: payments,
            estimated: contract.estimated || [],
            convention_type: contract.convention_type || [],
            related_contracts: related_contracts
        };
    }

    getSubTitle(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(0, numberValues).join(', ');
        } else {
            return values ? values.join(', ') : '';
        }
    }

    getTooltipValue(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(numberValues).join(', ');
        }
    }

    getStampForTootltip(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return [{ value: '+' + (values.length - numberValues), classList: 'stamp--small' }];
        }
    }

    getContractInfo(contract: Contract) {
        return contract.name;
    }

    getContractAdvisor(lnk_advisor_id) {
        return this.advisorOptions.find((item) => {
            return item.key == lnk_advisor_id;
        })?.value;
    }

    getCompanyName(dossiers) {
        if (dossiers) {
            return dossiers[0].company_name;
        } else {
            return undefined;
        }
    }

    getContractName() {
        if (this.contract.lnk_contract_uuid) {
            return this.contract.versions.find((item) => {
                return item.id == this.contract.lnk_contract_uuid;
            }).name;
        } else return this.contract.name;
    }

    getContractTitle() {
        return this.contract.name;
        // if (this.contract.lnk_contract_uuid) {
        //     return this.contract.name;
        // } else return this.contract.alias;
    }

    getContractType() {
        if (this.contract.lnk_contract_uuid) {
            const type = this.contract.versions.find((item) => {
                return item.id == this.contract.lnk_contract_uuid;
            }).contract_type;
            return this.resourceService.getResourceTitleByTypeAndKey('contract_type', type);
        }
        return this.resourceService.getResourceTitleByTypeAndKey('contract_type', this.contract.contract_type);
    }

    getAmendmentType(type) {
        return this.resourceService.getResourceTitleByTypeAndKey('amendment_type', type);
    }

    getProcedureArticle() {
        if (this.contract.procedure_article) {
            return this.resourceService.getResourceTitleByTypeAndKey(
                'procedure_article',
                this.contract.procedure_article
            );
        }
    }
    getProcedureArticleAmendment() {
        if (this.contract.procedure_article) {
            return this.resourceService.getResourceTitleByTypeAndKey(
                'procedure_article_amendment',
                this.contract.procedure_article
            );
        }
    }

    // getInitialVersion() {
    //     if (this.contract.lnk_contract_uuid) {
    //         const contract = this.contract.versions.find((item) => {
    //             return item.id == this.contract.lnk_contract_uuid;
    //         });
    //         return contract;
    //     }
    //     return this.contract;
    // }

    versionChange(id: string) {
        this.saving = true;
        this.loadingVersion = true;
        this.helper.version = id;
        this.router.navigate([], {
            queryParams: {
                contractId: id
            },
            queryParamsHandling: 'merge'
        });

        let page = null;
        switch (this.activePage) {
            case 'summary':
            case 'estimated':
                page = 'overview';
                break;
            default:
                page = this.activePage;
                break;
        }

        setTimeout(() => {
            this.getContract(id, page);
        }, 300);
    }

    createUpdate() {
        let FORM: any = {};
        switch (this.activePage) {
            case 'financial':
                FORM = { ...this.financialSchemeFormSTR };
                break;
            case 'payments':
                FORM = { ...this.paymentFormSTR };
                break;
            case 'estimated':
                FORM = { ...this.estimatedFormSTR };
                break;
            case 'summary':
                FORM = { ...this.summaryFormSTR };
                break;
            default:
                FORM = { ...this.formSTR };
                if (this.editAmendment == 'new') {
                    FORM.lnk_contract_uuid = this.contract.versions.find((item) => {
                        return !item.lnk_contract_uuid;
                    }).id;
                    delete FORM.id;
                } else {
                    delete FORM.lnk_contract_uuid;
                }
                if (
                    ![
                        'contract_negotiation_failed',
                        'no_agreement',
                        'contract_negotiation_refused',
                        'contract_negotiation_initiated'
                    ].includes(FORM.stage)
                ) {
                    delete FORM.year_of_implementation_or_failure;
                }
                break;
        }
        this.saving = true;
        let refreshResources = false;
        if (FORM.umbrella_contract) {
            const item = this.options.umbrella_contract.find((item) => {
                return item.new && item.key === FORM.umbrella_contract;
            });
            if (item) {
                FORM.new_umbrella_contract = item.value;
                FORM.umbrella_contract = undefined;
                delete FORM.umbrella_contract;
                refreshResources = true;
            }
        }
        this.contractsService
            .updateInsertContract(FORM, this.activePage, this.id || null)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe({
                next: (next) => {
                    this.validation = {};
                    if (!this.id) {
                        this.id = next['id'];
                        this.contract.id = this.id;
                        this.formSTR.id = this.id;
                        this.created.emit(this.formSTR);
                        var elements = document.getElementsByClassName('detail-modal--create');
                        while (elements.length > 0) {
                            elements[0].classList.remove('detail-modal--create');
                        }
                    } else {
                        if (this.editAmendment) {
                            this.router.navigate([], {
                                queryParams: {
                                    contractId: next['id']
                                },
                                queryParamsHandling: 'merge'
                            });
                            this.resetAmendmentProps();
                            this.id = next['id'];
                            this.contract.id = this.id;
                            this.formSTR.id = this.id;
                        }
                        this.updated.emit(this.formSTR);
                    }
                    if (refreshResources) {
                        forkJoin([...this.resourceService.init()]).subscribe((next) => {
                            this.initOptions();
                            this.changes = false;
                            this.getContract();
                            this.mode = 'show';
                        });
                    } else {
                        this.changes = false;
                        this.getContract();
                        this.mode = 'show';
                    }
                },
                error: (error) => this.handleError(error)
            });
    }

    isValidNumber(number: number): boolean {
        return isFinite(number) && !isNaN(number);
    }

    indexOfFirstDigit(input) {
        let i = 0;
        for (; input[i] < '0' || input[i] > '9'; i++);
        return i == input.length ? -1 : i;
    }

    getAdvisors() {
        this.usersService
            .getUsers({
                startRow: 0,
                RPP: 99999,
                orderBy: null,
                dossierId: null,
                term: null,
                company_ids: null,
                sam_company_ids: null
            })
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((next) => {
                this.advisorOptions = next.data
                    .filter((item) => {
                        return item.is_contract_manager;
                    })
                    .map((item) => {
                        return { value: item.firstname + ' ' + item.lastname, key: item.id };
                    });
            });
    }

    formChange() {
        this.changes = true;
    }

    resetForm() {
        this.formSTR = JSON.parse(JSON.stringify(this.contract));
    }

    cancelEdit() {
        this.editAmendment = null;
        if (this.canExit()) {
            this.mode = 'show';
            this.resetForm();
        }
        return true;
    }

    canExit(): boolean {
        if (!this.changes || confirm(this.translatePipe.transform('exit'))) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    getActionIndex() {
        return this.helper.actionIndex;
    }
    setActionIndex(i, j?) {
        this.helper.actionIndex = i;
        this.helper.actionIndexJ = j;
    }

    clickOutsideActions() {}

    actionClickPayments(item: any, action: string) {
        switch (action) {
            case 'edit':
                this.initCreateUpdatePayment(item);
                break;
            case 'delete':
                this.deletePayment(item.uuid);
                break;
            case 'copy':
                this.openCopyPaymentsModal(item.uuid);
                break;
        }
    }

    actionClickEstimated(item: any, action: string) {
        switch (action) {
            case 'edit':
                this.initCreateUpdateEstimated(item);
                break;
            case 'delete':
                this.deleteEstimated(item.uuid);
                break;
            case 'copy':
                this.openCopyEstimatedModal(item.uuid);
                break;
        }
    }

    deleteEstimated(uuid: string) {
        this.dismissPopover();
        this.contractsService.deleteEstimated(uuid).subscribe((next) => {
            this.getContract();
        });
    }

    openCopyEstimatedModal(lnk_estimated_uuid?, monthYear?) {
        const modalRef = this.ModalService.open(ContractModalEstimatedCopyComponent, {
            windowClass: 'main-modal options-modal',
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
            }
        });
        this.initEstimatedOptions();
        modalRef.componentInstance.options = this.optionsEstimated;
        modalRef.componentInstance.multiple = monthYear ? true : false;
        modalRef.componentInstance.confirmed.subscribe((next) => {
            const form = {
                ...next.formSTR,
                lnk_estimated_uuid: lnk_estimated_uuid,
                lnk_contract_uuid: this.contract.lnk_contract_uuid || this.contract.id,
                copyFromMonthYear: monthYear
            };
            this.contractsService.copyEstimated(form).subscribe((next) => {
                this.getContract();
            });
        });
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    drag(e) {
        e.dataTransfer.setData('id', e.target.id);
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        if (this.changes) return false;
    }

    handleError(error) {
        if (error.status == 500 || error.status == 403 || error.status == 404) {
            this.mode = 'globalError';
            this.changes = false;
        }
        this.validation = error.error.details;
    }
}
