import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart } from '@angular/router';
import { Router } from '@angular/router';
import { Event as NavigationEvent } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FieldSwitchOptionsModel } from 'src/app/components/fields/field-switch/field-switch.component';
import { HelpersService } from 'src/app/services/helpers.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { NotulenService } from 'src/app/services/notulen.service';
import { TranslatePipe } from 'src/app/utils/pipes';

@Component({
    selector: 'app-notules',
    templateUrl: './notules.component.html',
    styleUrls: ['./notules.component.scss'],
    standalone: false
})
export class NotulesComponent implements OnInit, OnDestroy {
    onDestroy$: Subject<void> = new Subject<void>();
    searchSTR: any = {};
    switchOptions: FieldSwitchOptionsModel;
    popoverHelper: any = null;

    tableHeads: any;
    tableSort: { code: string; dir: string };
    tableSortName = 'notules_tableSort';

    notules: any[] = null;
    originalCompanies: any[] = null;
    startRow: number = 0;
    RPP: number = 20;
    totalRows: number = null;
    tableLoading: boolean = true;
    searchType: string = 'advanced';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private notulenService: NotulenService,
        private translatePipe: TranslatePipe,
        private helpersService: HelpersService,
        private localStorageService: LocalStorageService
    ) {
        // router.events
        //     .pipe(
        //         filter((event: NavigationEvent) => {
        //             return event instanceof NavigationStart;
        //         })
        //     )
        //     .subscribe((event: NavigationStart) => {
        //         if (event.restoredState) {
        //             window.location.href = event.url;
        //         }
        //     });
        // route.queryParams.subscribe((params) => {
        //     const parameters = Object.assign({}, params);
        //     this.startRow = (parameters.page || 1) * this.RPP - this.RPP;
        //     this.searchSTR = params.query ? JSON.parse(params.query) : this.getDefaultSearchSTR();
        //     this.getNotules();
        // });
    }

    ngOnInit(): void {
        this.switchOptions = [
            {
                title: this.translatePipe.transform('notules_searchForm_title_'),
                value: 'advanced'
            }
        ];

        this.tableHeads = [
            {
                name: this.translatePipe.transform('notules_table_document'),
                code: 'fileName',
                sortable: true,
                width: '50%'
            },
            {
                name: this.translatePipe.transform('notules_table_date'),
                code: 'date',
                sortable: true,
                width: '50%'
            },
            {
                name: '',
                code: 'actions'
            }
        ];

        this.tableSort = this.getTableSort();
        if (!this.tableSort) {
            this.tableSort = { code: 'fileName', dir: 'desc' };
        }

        this.router.events
            .pipe(
                filter((event: NavigationEvent) => {
                    return event instanceof NavigationStart;
                })
            )
            .subscribe((event: NavigationStart) => {
                if (event.restoredState) {
                    window.location.href = event.url;
                }
            });
        this.route.queryParams.subscribe((params) => {
            const parameters = Object.assign({}, params);
            this.startRow = (parameters.page || 1) * this.RPP - this.RPP;
            this.searchSTR = params.query ? JSON.parse(params.query) : this.getDefaultSearchSTR();
            this.getNotules();
        });
    }

    private getTableSort() {
        const tableSortLS = this.localStorageService.getItem(this.tableSortName);
        if (
            tableSortLS &&
            this.tableHeads.filter((item) => item.code == tableSortLS.code && item.sortable == true).length == 1
        ) {
            return tableSortLS;
        }

        return null;
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    getDefaultSearchSTR() {
        return { status: 'all' };
    }

    getSearchQuery() {
        return {
            ...this.searchSTR,
            startRow: this.startRow,
            RRP: this.RPP,
            orderBy: this.getSortARR()
        };
    }

    getSortARR() {
        if (!this.tableSort || !this.tableSort.code) {
            return [];
        }
        return [`${this.tableSort.code} ${this.tableSort.dir}`];
    }

    getSortingClass(code) {
        if (this.tableSort.code === code) {
            return this.tableSort.dir;
        }
    }

    setSort(code: string, dir: string) {
        this.tableSort = { code: code, dir: dir };
        this.localStorageService.setItem(this.tableSortName, this.tableSort);
        this.getNotules();
    }

    resetStartRow() {
        this.router.navigate([], {
            queryParams: {
                page: 1
            },
            queryParamsHandling: 'merge'
        });
    }

    changeStartRow(startRow: number) {
        this.router.navigate([], {
            queryParams: {
                page: Math.ceil(startRow / this.RPP) + 1
            },
            queryParamsHandling: 'merge'
        });
    }

    search(reset?: boolean) {
        this.startRow = 0;
        if (reset) {
            this.router.navigate([], {
                queryParams: {
                    query: null,
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        } else {
            this.router.navigate([], {
                queryParams: {
                    query: JSON.stringify(this.searchSTR),
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        }
    }

    getNotules() {
        const searchSTR = this.getSearchQuery();
        this.tableLoading = true;
        this.notulenService
            .search(searchSTR)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((next) => {
                if (this.startRow && this.startRow >= next.rows) {
                    this.resetStartRow();
                    return;
                }
                this.notules = next.data.map((item: any) => {
                    const notulen = {
                        ...item,
                        fileName: {
                            type: 'link',
                            linkName: item.fileName,
                            link: item.blobUrl,
                            linkId: item.id,
                            target: '_blank',
                            class: 'td-filename'
                        },
                        date: {
                            type: 'ts',
                            ts: item.date,
                            format: 'prettyTs'
                        },
                        actions: {
                            type: 'actions',
                            actions: this.getActions()
                        }
                    };
                    return notulen;
                });
                this.tableLoading = false;
                this.totalRows = next.rows;
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            });
    }

    getActions() {
        const actions = [];
        const deleteAction = {
            name: this.translatePipe.transform('notules_table_delete'),
            code: 'delete',
            icon: 'trash',
            class: 'delete-red',
            confirm: true,
            confirm_type: 'delete'
        };
        actions.push(deleteAction);
        return actions;
    }

    deleteNotulen(notuleId) {
        this.dismissPopover();
        this.notulenService.delete(notuleId).subscribe((next) => {
            this.notules = this.notules.filter((item) => item.id != notuleId);
        });
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'delete':
                this.deleteNotulen(item.id);
                break;
        }
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }
}
