import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-document-contract-options',
    templateUrl: './modal-document-contract-options.component.html',
    styleUrls: ['./modal-document-contract-options.component.scss'],
    standalone: false
})
export class ModalDocumentContractOptionsComponent implements OnInit {
    @Input() files: any[] = [];
    @Input() file: any = null;
    @Input() rejected: any[] = [];
    @Input() dossiers: any[] = [];
    @Output() confirmed = new EventEmitter();

    FORM: any[] = [];

    constructor(private ActiveModal: NgbActiveModal) {}

    ngOnInit(): void {
        if (this.file) {
            this.FORM = this.file?.dossiers.map((item) => {
                return item.id;
            });
            this.files = [{ name: this?.file?.file_name?.linkName }];
        }
    }

    getRejectedReason(file) {
        if (file.reason == 'size') {
            return `${file.name}: Can be maximum 50 MB in size`;
        } else {
            return `${file.name}: Can't be uploaded because of ${file.reason}`;
        }
    }

    isChecked(id) {
        return this.FORM.find((item) => {
            return item == id;
        });
    }

    check(id) {
        const index = this.FORM.findIndex((item) => {
            return item == id;
        });
        if (index != -1) {
            this.FORM.splice(index, 1);
        } else this.FORM.push(id);
    }

    submit() {
        if (this.files && !this.file) {
            this.confirmed.emit({
                files: this.files,
                dossierIds: this.FORM
            });
        } else if (this.file) {
            this.confirmed.emit({
                file: this.file,
                dossierIds: this.FORM
            });
        }
        this.ActiveModal.dismiss();
    }

    cancel() {
        this.ActiveModal.dismiss();
    }
}
