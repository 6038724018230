import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, map } from 'rxjs/operators';
import { ContractsService } from 'src/app/services/contracts.service';

@Component({
    selector: 'app-field-contract',
    templateUrl: './field-contract.component.html',
    styleUrls: ['./field-contract.component.scss'],
    standalone: false
})
export class FieldContractComponent implements OnInit {
    @Input() label?: string;
    @Input() error?: string;
    @Input() placeholder?: string;
    @Input() model: any = null;
    @Input() initialContracts?: boolean = false;
    @Output() modelChange = new EventEmitter();

    selected: any = '';

    constructor(private ContractsService: ContractsService) {}

    ngOnInit(): void {
        // if (this.model && this.model !== undefined) {
        //     this.selected = this.model;
        //     this.modelChange.emit(this.selected);
        // }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.model) {
            this.selected = changes.model.currentValue || null;
        }
    }

    formatter = (item: { key: any; value: string }) => item.value;

    public onFocus(e: Event): void {
        e.stopPropagation();
        setTimeout(() => {
            const inputEvent: Event = new Event('input');
            e.target.dispatchEvent(inputEvent);
        }, 0);
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter((term) => {
                return term.length > 1;
            }),
            switchMap((term) =>
                this.ContractsService.getContracts({
                    initialContracts: this.initialContracts,
                    term: term,
                    startRow: 0,
                    RPP: 20
                })
            ),
            map((next: any) => {
                let contracts = [];

                for (const contract of next.data) {
                    const products = contract?.products?.map((item) => {
                        return item.product;
                    });
                    contracts.push({
                        key: contract.id,
                        value: contract.number,
                        contract: contract,
                        products: products,
                        title: `${contract.number ? contract.number : 'No number - '} ${
                            contract.name ? contract.name : 'No name'
                        } - ${contract.type ? contract.type : 'No type'}`,
                        subtitle: products?.length ? `products: ${this.getSubTitle(products, 1)}` : ``,
                        tooltip: this.getTooltipValue(products, 1),
                        stamp: this.getStampForTootltip(products, 1)
                    });
                }

                if (contracts.length == 0) {
                    contracts.push({
                        key: '',
                        value: 'No contract found.',
                        name: null,
                        title: 'No contract found.'
                    });
                }

                return contracts;
            })
        );

    getSubTitle(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(0, numberValues).join(', ');
        } else {
            return values ? values.join(', ') : '';
        }
    }

    getTooltipValue(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(numberValues).join(', ');
        }
    }

    getStampForTootltip(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return [{ value: '+' + (values.length - numberValues), classList: 'stamp--small' }];
        }
    }

    onSelect($event) {
        this.modelChange.emit($event.item.key);
    }

    onBlur($event?) {
        if (!this.selected) {
            this.selected = '';
            this.modelChange.emit(undefined);
        }
    }
}
