import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';

@Component({
    selector: 'app-public-header',
    templateUrl: './public-header.component.html',
    styleUrls: ['./public-header.component.scss'],
    standalone: false
})
export class PublicHeaderComponent implements OnInit {
    appName: string;

    constructor(public ConfigService: ConfigService) {}

    ngOnInit(): void {
        this.appName = this.ConfigService.getConfig().appName;
    }
}
