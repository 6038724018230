import { Pipe, PipeTransform } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import * as moment from 'moment';
import 'moment/locale/nl-be';
moment.locale('nl-be');
import { TranslatorService } from '../services/translator.service';
import { DomSanitizer } from '@angular/platform-browser';

const keywordAt = 'om';
const keywordToday = 'vandaag';
const today = moment();

@Pipe({
    name: 'formatTs',
    standalone: false
})
export class formatTsPipe implements PipeTransform {
    transform(ts: string, format: string): string {
        if (!ts || !format || !moment(ts).isValid()) {
            return ts || '-';
        }
        switch (format) {
            case 'prettyTs':
                return moment(ts).calendar(null, {
                    sameDay: '[Today]',
                    nextDay: '[Tomorrow]',
                    nextWeek: 'DD-MM-YYYY',
                    lastDay: '[Yesterday]',
                    lastWeek: 'DD-MM-YYYY',
                    sameElse: 'DD-MM-YYYY'
                });
                break;
            case 'prettyDateTime':
                return moment(ts).calendar(null, {
                    sameDay: '[Today] HH:mm',
                    nextDay: '[Tomorrow] HH:mm',
                    nextWeek: 'DD-MM-YYYY HH:mm',
                    lastDay: '[Yesterday] HH:mm',
                    lastWeek: 'DD-MM-YYYY HH:mm',
                    sameElse: 'DD-MM-YYYY HH:mm'
                });
                break;
            default:
                break;
        }
        return moment(ts).format(format);
    }
}
@Pipe({
    name: 'display',
    standalone: false
})
export class displayPipe implements PipeTransform {
    transform(value: any, type): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        }

        return value;
    }
}

@Pipe({
    name: 'floatDisplay',
    standalone: false
})
export class floatDisplayPipe implements PipeTransform {
    transform(value: any, type): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        }

        if (!isNaN(parseFloat(value))) {
            let r = parseFloat(value).toString().replace(/\./g, ',');
            if (type && type == 'thousandSeparator') {
                return r.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            } else {
                return r;
            }
        }

        return value;
    }
}

@Pipe({
    name: 'numberDisplay',
    standalone: false
})
export class numberDisplayPipe implements PipeTransform {
    transform(value: any, type): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        }

        if (!isNaN(parseInt(value))) {
            let r = parseInt(value).toString();
            if (type && type == 'thousandSeparator') {
                return r.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            } else {
                return r;
            }
        }

        return value;
    }
}

@Pipe({
    name: 'booleanDisplay',
    standalone: false
})
export class booleanDisplayPipe implements PipeTransform {
    constructor(private TranslatorService: TranslatorService) {}
    transform(value: any, type): any {
        if (typeof value === 'string') {
            const v = value.toLowerCase();
            if (v == '1' || v == 'true') {
                return this.TranslatorService.getTranslation('crmCtgDossier_true');
            } else if (v == '0' || v == 'false') {
                return this.TranslatorService.getTranslation('crmCtgDossier_false');
            } else if (v == 'n/a') {
                return 'N/A';
            }
        }

        if (typeof value == 'number') {
            if (value == 1) {
                return this.TranslatorService.getTranslation('crmCtgDossier_true');
            } else if (value == 0) {
                return this.TranslatorService.getTranslation('crmCtgDossier_false');
            }
        }

        if (typeof value == 'boolean') {
            if (!value) {
                return this.TranslatorService.getTranslation('crmCtgDossier_false');
            } else if (value) {
                return this.TranslatorService.getTranslation('crmCtgDossier_true');
            }
        }

        if (value == 'na') {
            return this.TranslatorService.getTranslation('misc_na');
        }

        return '-';
    }
}

@Pipe({
    name: 'customCurrency',
    standalone: false
})
export class customCurrencyPipe implements PipeTransform {
    constructor(private cp: CurrencyPipe) {}
    transform(value: any, round?: boolean, noValuta?: boolean): any {
        let result = '';
        if (value === 0 || value === '0') {
            result = round ? '€ 0' : '€ 0,00';
        } else if (!value) {
            result = '-';
        } else {
            let returnValue = this.cp.transform(parseFloat(value).toString(), '€ ').replace(/,/g, '.');
            const n = returnValue.lastIndexOf('.');
            if (n >= 0 && returnValue.length) {
                returnValue = returnValue.substring(0, n) + ',' + returnValue.substring(n + 1);
            }
            if (round) {
                returnValue = returnValue.substring(0, returnValue.length - 3);
            }
            result = returnValue;
        }
        if (noValuta) {
            result = result.replace('€ ', '');
        }
        return result;
    }
}
@Pipe({
    name: 'customPercentage',
    standalone: false
})
export class percentagePipe implements PipeTransform {
    transform(value: any): any {
        if (value === 0 || value === '0') {
            return '0%';
        }
        if (!value) {
            return '-';
        } else return value.toString().replace('.', ',') + '%';
    }
}

@Pipe({
    name: 'translate',
    standalone: false
})
export class TranslatePipe implements PipeTransform {
    constructor(private TranslatorService: TranslatorService) {}
    public transform(key: string): string {
        return this.TranslatorService.getTranslation(key);
    }
}

@Pipe({
    name: 'array',
    standalone: false
})
export class ArrayPipe implements PipeTransform {
    constructor() {}
    public transform(arr: any[]): string {
        if (!arr?.length) return '-';
        return arr.join(', ');
    }
}

@Pipe({
    name: 'safe',
    standalone: false
})
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    transform(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

@Pipe({
    name: 'callback',
    pure: false,
    standalone: false
})
export class callbackPipe implements PipeTransform {
    transform(items: any[], callback: (item: any, data?: any) => boolean, data?): any {
        if (!items || !callback) {
            return items;
        }
        return items.filter((item) => callback(item, data));
    }
}
