import { CurrencyPipe } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';

@Component({
    selector: 'app-field-text',
    templateUrl: './field-text.component.html',
    styleUrls: ['./field-text.component.scss'],
    standalone: false
})
export class FieldTextComponent implements OnChanges, AfterViewInit {
    @Input() label?: string;
    @Input() error?: string;
    @Input() errorTooltip?: string;
    @Input() optionalString?: string;
    @Input() afterString?: string;
    @Input() type?: 'integer' | 'float' | 'currency' | 'password' | 'email' | '';
    @Input() typeClass?: string;
    @Input() placeholder?: string = '';
    @Input() mask?: string = '';
    @Input() thousandSeparator?: string = '';
    @Input() maskPrefix?: string = '';
    @Input() showMaskTyped: string = 'true';
    @Input() autocomplete: string = '';
    @Input() model: string;
    @Input() inputWidth: string;
    @Input() disabled: boolean = false;
    @Input() instant: boolean = false;
    @Input() focus: boolean = false;
    @Output() modelChange = new EventEmitter();
    @Output() emitFocus = new EventEmitter();
    @Output() emitBlur = new EventEmitter();
    @Output() changed = new EventEmitter();
    @Output() enter = new EventEmitter();

    localModel: any;
    togglePwd: boolean = false;

    constructor(private currencyPipe: CurrencyPipe) {}

    @ViewChild('myinput') myInputField: ElementRef;
    ngAfterViewInit() {
        if (this.focus) {
            this.myInputField.nativeElement.focus();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.model || changes.type) {
            let preventEmit = true;
            if (changes.type && changes.type.previousValue) {
                preventEmit = false;
            }
            if (this.type == 'float') {
                this.localModel = this.model;
                setTimeout(() => {
                    this.formatFloat(preventEmit);
                }, 1);
            } else if (this.type == 'currency') {
                this.localModel = this.model;
                setTimeout(() => {
                    this.formatCurrency(preventEmit);
                }, 1);
            } else if (this.type == 'integer') {
                this.localModel = this.model;
                setTimeout(() => {
                    this.formatInteger(preventEmit);
                }, 1);
            } else this.localModel = this.model;
        }
    }

    changes() {
        if (this.instant) {
            setTimeout(() => {
                this.onBlur();
            }, 1);
        }
        this.changed.emit();
    }

    onBlur($event?) {
        if (this.type == 'float') {
            this.formatFloat();
        } else if (this.type == 'integer') {
            this.formatInteger();
        } else if (this.type == 'currency') {
            this.formatCurrency();
        } else {
            this.modelChange.emit(this.localModel);
        }
        this.emitBlur.emit();
    }

    formatFloat(preventEmit?: boolean) {
        if (!this.localModel) {
            this.localModel = undefined;
            if (!preventEmit) {
                this.modelChange.emit(undefined);
            }
            return;
        }
        let numericValue = this.localModel.toString().replace(/,/g, '.');
        if (isNaN(parseFloat(numericValue))) {
            this.localModel = undefined;
            if (!preventEmit) {
                this.modelChange.emit(undefined);
            }
        } else {
            // setTimeout(() => {
            this.localModel = this.twoDecimals(parseFloat(numericValue)).toString().replace(/\./g, ',');
            if (!preventEmit) {
                this.modelChange.emit(this.twoDecimals(parseFloat(numericValue)));
            }
            // }, 1);
        }
    }

    formatCurrency(preventEmit?: boolean) {
        if (this.localModel == null || this.localModel == undefined) {
            this.localModel = undefined;
            if (!preventEmit) {
                this.modelChange.emit(undefined);
            }
            return;
        }
        let numericValue = this.localModel.toString().replace(/\./g, ',');
        const n = numericValue.lastIndexOf(',');
        if (n >= 0 && numericValue.length) {
            numericValue = numericValue.substring(0, n) + '.' + numericValue.substring(n + 1);
        }
        numericValue = numericValue.replace(/,/g, '');
        numericValue = numericValue.replace(/€/g, '');
        if (isNaN(parseFloat(numericValue))) {
            this.localModel = undefined;
            if (!preventEmit) {
                this.modelChange.emit(undefined);
            }
        } else {
            let returnValue = this.currencyPipe
                .transform(parseFloat(numericValue).toString().replace(/,/g, '.'), '€')
                .replace(/,/g, '.')
                .replace(/€/g, '');
            const n = returnValue.lastIndexOf('.');
            if (n >= 0 && returnValue.length) {
                returnValue = returnValue.substring(0, n) + ',' + returnValue.substring(n + 1);
            }
            this.localModel = returnValue;
            if (!preventEmit) {
                this.modelChange.emit(parseFloat(numericValue));
            }
        }
    }

    formatInteger(preventEmit?: boolean) {
        if (!this.localModel && this.localModel !== 0) {
            this.localModel = undefined;
            if (!preventEmit) {
                this.modelChange.emit(undefined);
            }
            return;
        }
        let numericValue = parseInt(this.localModel);
        if (isNaN(numericValue)) {
            this.localModel = undefined;
            if (!preventEmit) {
                this.modelChange.emit(undefined);
            }
        } else {
            this.localModel = numericValue;
            // setTimeout(() => {
            if (!preventEmit) {
                this.modelChange.emit(numericValue);
            }
            // }, 1);
        }
    }

    getType() {
        if (this.type && ((this.type == 'password' && !this.togglePwd) || this.type == 'email')) {
            return this.type;
        }
        return 'text';
    }

    twoDecimals(number) {
        return Math.round(number * 100) / 100;
    }
}
