import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as sanitizeHtml from 'sanitize-html';
@Component({
    selector: 'app-field-richtext',
    templateUrl: './field-richtext.component.html',
    styleUrls: ['./field-richtext.component.scss'],
    standalone: false
})
export class FieldRichtextComponent implements OnInit {
    @Input() label?: string = '';
    @Input() afterString?: string;
    @Input() error?: string;
    @Input() placeholder?: string = '';
    @Input() model: string = '';
    @Input() readOnly?: boolean;
    @Output() modelChange = new EventEmitter();
    @Output() enter = new EventEmitter();
    modules;

    constructor() {}

    ngOnInit(): void {
        if (this.readOnly) {
            this.modules = { toolbar: false };
        } else {
            this.modules = {
                toolbar: [
                    ['bold', 'italic', 'underline'], // toggled buttons
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ indent: '-1' }, { indent: '+1' }]
                ]
            };
        }
        this.model = sanitizeHtml(this.model, {
            allowedTags: ['p', 'em', 'strong', 'u', 'ol', 'li', 'ul'],
            allowedClasses: {
                p: ['ql-indent-*']
            }
        });
    }

    valueChanged(value: any) {
        this.modelChange.emit(value.html);
    }
}
