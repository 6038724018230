import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss'],
    standalone: false
})
export class PopoverComponent implements OnInit {
    @Input() open?: boolean;
    @Input() positionClass?: 'left' | 'right' | 'center' | 'fixed' = 'center';

    constructor() {}

    ngOnInit(): void {}
}
