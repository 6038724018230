import { Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
    selector: 'app-field-radios',
    templateUrl: './field-radios.component.html',
    styleUrls: ['./field-radios.component.scss'],
    standalone: false
})
export class FieldRadiosComponent implements OnInit, OnChanges {
    @Input() options: [{ title: string; value: any }];
    @Input() optionWidth: number;
    @Input() optionColumnWidth?: string;
    @Input() fillWidth?: boolean;
    @Input() canUncheck?: boolean;
    @Input() readOnly?: boolean;
    @Input() model: any;
    @Input() label?: string;
    @Input() tooltip?: string;
    @Input() error?: string;
    @Input() optionalString?: string;
    @Input() optionalInput: boolean = false;
    @Input() optionalInputOnModelValue?: string;
    @Input() optionalInputLabel?: string;
    @Input() optionalInputModel?: string;
    @Input() optionalInputWidth?: number;
    @Input() optionalInputPlaceholder?: string = '';
    @Input() optionalInputType?: 'integer' | 'float' | 'currency' | 'password' | 'email' | '';
    @Input() optionalReadOnlyOnOtherModelValue?: boolean = false;
    @Input() optionalReadOnlyValue?: string;
    @Output() modelChange = new EventEmitter();
    @Output() optionalInputModelChange = new EventEmitter();
    @Output() emitBlur = new EventEmitter();

    localModel: any;
    localOptionalInputModel: any;
    localOptionalValueReadOnly: boolean;
    localOptionalInput: boolean;

    constructor() {}

    ngOnInit(): void {
        this.checkShowOptionalInput();
        setTimeout(() => {
            if (this.options?.length && this.model && !this.options.some((option) => option.value === this.model)) {
                this.modelChange.emit(undefined);
            }
        }, 1);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.optionalInputModel || changes.type) {
            let preventEmit = true;
            if (changes.type && changes.type.previousValue) {
                preventEmit = false;
            }
            if (this.optionalInputType == 'float') {
                this.localOptionalInputModel = this.optionalInputModel;
                setTimeout(() => {
                    this.formatFloat(preventEmit);
                }, 1);
            } else this.localOptionalInputModel = this.optionalInputModel;
        }

        if (changes.model) {
            this.localModel = this.model;
        }
        this.checkShowOptionalInput();
    }

    checkShowOptionalInput() {
        if (
            (this.model != null &&
                this.optionalInputOnModelValue != null &&
                this.optionalInputOnModelValue.toString() === this.model.toString()) ||
            (this.optionalInput && this.optionalInputOnModelValue == undefined)
        ) {
            this.localOptionalInput = true;
            this.localOptionalValueReadOnly = false;
        } else {
            this.localOptionalInput = false;
            this.localOptionalInputModel = undefined;
            this.localOptionalValueReadOnly =
                this.optionalReadOnlyOnOtherModelValue == true && this.model ? true : false;
        }
    }

    formatFloat(preventEmit?: boolean) {
        if (!this.localOptionalInputModel) {
            this.localOptionalInputModel = undefined;
            if (!preventEmit) {
                this.optionalInputModelChange.emit(undefined);
            }
            return;
        }
        let numericValue = this.localOptionalInputModel.toString().replace(/,/g, '.');
        if (isNaN(parseFloat(numericValue))) {
            this.localOptionalInputModel = undefined;
            if (!preventEmit) {
                this.optionalInputModelChange.emit(undefined);
            }
        } else {
            // setTimeout(() => {
            this.localOptionalInputModel = parseFloat(numericValue).toString().replace(/\./g, ',');
            if (!preventEmit) {
                this.optionalInputModelChange.emit(parseFloat(numericValue));
            }
            // }, 1);
        }
    }

    onBlur($event?) {
        if (this.optionalInputType == 'float') {
            this.formatFloat();
        } else {
            this.optionalInputModelChange.emit(this.optionalInputModel);
        }
        this.emitBlur.emit();
    }

    optionalInputEmit($event) {
        this.optionalInputModelChange.emit($event);
    }

    emit($event) {
        if (this.readOnly) {
            return;
        }
        this.modelChange.emit($event);
    }
}
