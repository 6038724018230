import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, map } from 'rxjs/operators';
import { ProductService } from 'src/app/services/product.service';
import { Product } from 'src/app/utils/api';

@Component({
    selector: 'app-field-products',
    templateUrl: './field-products.component.html',
    styleUrls: ['./field-products.component.scss'],
    standalone: false
})
export class FieldProductsComponent implements OnInit {
    @Input() label?: string;
    @Input() error?: string | string[];
    @Input() placeholder?: string;
    @Input() model: Product[] = [];
    @Input() hideList: boolean = false;
    @Output() modelChange = new EventEmitter();

    selected = '';

    constructor(private productService: ProductService) {}

    ngOnInit(): void {
        if (this.model == null || this.model == undefined) {
            this.model = [];
        }
    }

    formatter = (item: { key: any; value: string }) => item.value;

    public onFocus(e: Event): void {
        e.stopPropagation();
        setTimeout(() => {
            const inputEvent: Event = new Event('input');
            e.target.dispatchEvent(inputEvent);
        }, 0);
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter((term) => {
                if (this.model && this.model.length > 0) {
                    return true;
                } else {
                    return term.length > 2;
                }
            }),
            switchMap((term) => this.productService.searchProducts(term, this.model)),
            map((val) => {
                let producten = [];
                for (const product of val.data) {
                    producten.push({
                        key: product.uuid,
                        value: this.getProductInfo(product),
                        product: product
                    });
                }

                if (producten.length == 0) {
                    producten.push({
                        key: '',
                        value: 'No package found.',
                        product: null
                    });
                }
                return producten;
            })
        );

    onSelect($event) {
        if ($event.item.product) {
            this.model.push($event.item.product);
            this.modelChange.emit(this.model);
        }

        $event.preventDefault();

        setTimeout(() => {
            this.selected = '';
        }, 200);
    }

    getProductInfo(product: Product) {
        return product.productPackage;
    }

    deleteValue(index) {
        this.model.splice(index, 1);
        this.modelChange.emit(this.model);
    }
}
