import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, map } from 'rxjs/operators';
import { HelpersService } from 'src/app/services/helpers.service';
import { ProductService } from 'src/app/services/product.service';
import { ResourceService } from 'src/app/services/resource.service';
import { Product } from 'src/app/utils/api';

@Component({
    selector: 'app-contract-products',
    templateUrl: './contract-products.component.html',
    styleUrls: ['./contract-products.component.scss'],
    standalone: false
})
export class ContractProductsComponent implements OnInit {
    @Input() label?: string;
    @Input() error?: string | string[];
    @Input() placeholder?: string;
    @Input() model: any[] = [];
    @Input() companyId?: string;
    @Input() readOnly: boolean = false;
    @Output() modelChange = new EventEmitter();

    selected = '';

    impactOptions: any[] = [];

    constructor(
        private productService: ProductService,
        public resourceService: ResourceService,
        public HelpersService: HelpersService
    ) {}

    ngOnInit(): void {
        if (this.model == null || this.model == undefined) {
            this.model = [];
        }

        this.impactOptions = this.resourceService.getResourceByType('contract_product_impact');
    }

    formatter = (item: { key: any; value: string }) => item.value;

    public onFocus(e: Event): void {
        e.stopPropagation();
        setTimeout(() => {
            const inputEvent: Event = new Event('input');
            e.target.dispatchEvent(inputEvent);
        }, 0);
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter((term) => {
                if (this.model && this.model.length > 0) {
                    return true;
                } else {
                    return term.length > 2;
                }
            }),
            switchMap((term) =>
                this.productService.searchProducts(term, this.model, this.companyId, 'contractProducts')
            ),
            map((val) => {
                let producten = [];
                for (const product of val.data) {
                    producten.push({
                        key: product.uuid,
                        value: product.productPackage,
                        product: product
                    });
                }

                if (producten.length == 0) {
                    producten.push({
                        key: '',
                        value: 'No package found.',
                        product: null
                    });
                }
                return producten;
            })
        );

    onSelect($event) {
        if ($event.item.product) {
            this.model.push($event.item.product);
            this.modelChange.emit(this.model);
        }

        $event.preventDefault();

        setTimeout(() => {
            this.selected = '';
        }, 200);
    }

    deleteValue(index) {
        this.model.splice(index, 1);
        if (this.error && this.error[index]) {
            (this.error as any).splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    getPercentageLabel(impact) {
        let label: string = this.resourceService.getResourceTitleByTypeAndKey('contract_product_impact', impact);
        return '% ' + label.toLowerCase();
    }

    isArray(val) {
        if (val && val instanceof Array) {
            return true;
        }
        return false;
    }
}
