import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-field-typeahead',
    templateUrl: './field-typeahead.component.html',
    styleUrls: ['./field-typeahead.component.scss'],
    standalone: false
})
export class FieldTypeaheadComponent implements OnInit {
    @Input() label?: string;
    @Input() error?: string;
    @Input() optionalString?: string;
    @Input() placeholder?: string;
    @Input() editable?: boolean;
    @Input() initialModel: string;
    @Input() options: { key: any; value: string }[] | any[];
    @Input() optionsToExclude: string[] = [];
    @Input() searchKeyARR?: any[] = [];
    @Input() afterARR?: any[] = [];
    @Output() modelChange = new EventEmitter();
    @Input() model: any;
    @Input() clearModelOnSelect: boolean = false;
    // searchString: string = '';

    constructor() {}

    ngOnInit() {
        if (this.initialModel) {
            this.model = this.initialModel;
        }
        if (!this.searchKeyARR || !this.searchKeyARR.length) {
            this.searchKeyARR = ['value'];
        }
    }

    onSelect($event) {
        this.modelChange.emit($event.item.key);
        if (this.clearModelOnSelect) {
            setTimeout(() => {
                this.model = '';
            }, 200);
        }
    }

    formatter = (item: { key: any; value: string }) => item.value;

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter((term) => term.length >= 1),
            map((term) =>
                this.options
                    .filter((item) => {
                        let search = '';
                        this.searchKeyARR.forEach((key) => {
                            if (item[key] !== undefined && !this.getOptionsToExclude().includes(item['key'])) {
                                search += ` ${item[key]}`;
                            }
                        });
                        return new RegExp(term, 'mi').test(search);
                    })
                    .slice(0, 100)
            )
        );

    change($event) {
        if (!this.editable && !this.model) {
            this.modelChange.emit(undefined);
        }
    }

    onBlur($event?) {
        if (!this.model && !this.editable) {
            this.model = '';
        }
    }

    getOptionsToExclude() {
        if (this.optionsToExclude == null || this.optionsToExclude == undefined) {
            return [];
        }
        return this.optionsToExclude;
    }
}
