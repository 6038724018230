import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IndicationService } from 'src/app/services/indication.service';

@Component({
    selector: 'app-modal-add-indication',
    templateUrl: './modal-add-indication.component.html',
    styleUrls: ['./modal-add-indication.component.scss'],
    standalone: false
})
export class ModalAddIndicationComponent implements OnInit {
    @Input() label: string;
    @Output() confirmed = new EventEmitter();

    FORM: any = {};
    validation: any = {};
    saving: boolean = false;

    constructor(private ActiveModal: NgbActiveModal, private IndicationService: IndicationService) {}

    ngOnInit(): void {
        this.FORM.label = this.label || '';
    }

    submit() {
        this.saving = true;
        this.IndicationService.addIndication(this.FORM).subscribe({
            next: (next) => {
                this.confirmed.emit({
                    id: next.id,
                    label: next.label
                });
                this.ActiveModal.dismiss();
            },
            error: (error) => {
                this.validation = error.error.details;
            },
            complete: () => {
                this.saving = false;
            }
        });
    }

    cancel() {
        this.ActiveModal.dismiss();
    }
}
