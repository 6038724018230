import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    AfterViewInit,
    SimpleChanges,
    ViewChild,
    Injectable
} from '@angular/core';
import moment from 'moment';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
    readonly DELIMITER = '-';

    fromModel(value: string | null): NgbDateStruct | null {
        if (value) {
            if (moment.isMoment(value)) {
                value = value.format('YYYY-MM-DD');
            }
            const date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[2], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[0], 10)
            };
        }
        return null;
    }

    toModel(date: NgbDateStruct | null): string | null {
        // return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;

        if (!date) return null;
        return `${date.year.toString().padStart(4, '0')}${this.DELIMITER}${date.month.toString().padStart(2, '0')}${
            this.DELIMITER
        }${date.day.toString().padStart(2, '0')}`;
    }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    readonly DELIMITER = '-';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        // return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';

        if (!date) return null;
        return `${date.day.toString().padStart(2, '0')}${this.DELIMITER}${date.month.toString().padStart(2, '0')}${
            this.DELIMITER
        }${date.year.toString().padStart(4, '0')}`;
    }
}

@Component({
    selector: 'app-field-date',
    templateUrl: './field-date.component.html',
    styleUrls: ['./field-date.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: false
})
export class FieldDateComponent implements OnInit, OnChanges, AfterViewInit {
    // @ViewChild('dp') mydp: AngularMyDatePickerDirective;
    // @Input() positionDateSelector: string = 'top';
    @Input() label?: string;
    @Input() error?: string;
    @Input() optionalString?: string;
    @Input() placeholder?: string;
    @Input() model: string;
    @Input() focus: boolean = false;
    @Input() width: string = null;
    @Input() minDate: string = null;
    @Input() maxDate: string = null;
    @Input() classes: string = '';
    @Input() disableSince: string = null;
    @Input() disableUntil: string = null;
    @Output() modelChange = new EventEmitter();
    @Output() changed = new EventEmitter();
    @Output() emitValid = new EventEmitter();

    calendarIsOpen: boolean = false;
    isFocused: boolean = false;
    invalidDate: boolean = false;
    localModel: string = null;
    locale: string = 'en';

    localDisableSince: any = null;
    localDisableUntil: any = null;

    constructor() {}

    @ViewChild('myinput') myInputField: ElementRef;
    ngAfterViewInit() {
        if (this.focus) {
            this.myInputField.nativeElement.focus();
        }
    }

    ngOnInit(): void {
        if (this.disableSince) {
            const date = moment(this.disableSince).subtract(1, 'days');
            if (date.isValid()) {
                const day = date.date();
                const month = date.month() + 1;
                const year = date.year();
                this.localDisableSince = {
                    year: year,
                    month: month,
                    day: day
                };
            }
        }
        if (this.disableUntil) {
            const date = moment(this.disableUntil).add(1, 'days');
            if (date.isValid()) {
                const day = date.date();
                const month = date.month() + 1;
                const year = date.year();
                this.localDisableUntil = {
                    year: year,
                    month: month,
                    day: day
                };
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.model) {
            this.localModel = null;
            return;
        }
        const date = moment(this.model);
        if (!date.isValid()) return;
        const day = date.date();
        const month = date.month() + 1;
        const year = date.year();
        if (changes.model) {
            this.localModel = this.model;
        }
        if (this.model && this.minDate && moment(this.model).isSameOrBefore(moment(this.minDate))) {
            this.invalidDate = true;
        } else if (this.model && this.maxDate && moment(this.model).isSameOrAfter(moment(this.maxDate))) {
            this.invalidDate = true;
        } else {
            this.invalidDate = false;
        }
        this.emitValid.emit(!this.invalidDate);
    }

    changes() {
        this.changed.emit();
    }

    onDateChanged(event) {
        if (!event) {
            this.modelChange.emit(null);
            return;
        }
        const date = `${event.year.toString().padStart(4, '0')}-${event.month.toString().padStart(2, '0')}-${event.day
            .toString()
            .padStart(2, '0')}`;
        if (moment(date).isValid() && this.model !== this.localModel) {
            if (this.model !== this.localModel) this.modelChange.emit(date);
        } else this.modelChange.emit(null);
    }

    invalid() {
        setTimeout(() => {
            this.modelChange.emit(null);
        }, 1);
    }

    onBlur($event?) {
        this.isFocused = false;
        if (this.localModel && moment(this.localModel).isValid()) {
            if (this.model !== this.localModel) this.modelChange.emit(this.localModel);
        } else this.modelChange.emit(null);
    }

    calendarToggle($event) {
        if ($event == 1) {
            this.calendarIsOpen = true;
        } else this.calendarIsOpen = false;
    }

    onFocus() {
        this.isFocused = true;
    }
}
