import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'add-contact-person',
    templateUrl: './add-contact-person.component.html',
    styleUrls: ['./add-contact-person.component.scss'],
    standalone: false
})
export class AddContactPersonComponent implements OnInit {
    @Output() confirmed = new EventEmitter();

    formSTR: any = {};
    validation: any = {};

    constructor(private ActiveModal: NgbActiveModal) {}

    ngOnInit(): void {}

    submit() {
        this.confirmed.emit({
            formSTR: this.formSTR
        });

        this.ActiveModal.dismiss();
    }

    cancel() {
        this.ActiveModal.dismiss();
    }
}
