import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, map } from 'rxjs/operators';
import { ContractsService } from 'src/app/services/contracts.service';
import { Contract } from 'src/app/utils/api';

@Component({
    selector: 'app-field-contracts',
    templateUrl: './field-contracts.component.html',
    styleUrls: ['./field-contracts.component.scss'],
    standalone: false
})
export class FieldContractsComponent implements OnInit {
    @Input() label?: string;
    @Input() error?: string | string[];
    @Input() placeholder?: string;
    @Input() model: Contract[] = [];
    @Input() hideList: boolean = false;
    @Output() modelChange = new EventEmitter();

    selected = '';

    constructor(private contractsService: ContractsService) {}

    ngOnInit(): void {
        if (this.model == null || this.model == undefined) {
            this.model = [];
        }
    }

    formatter = (item: { key: any; value: string }) => item.value;

    public onFocus(e: Event): void {
        e.stopPropagation();
        setTimeout(() => {
            const inputEvent: Event = new Event('input');
            e.target.dispatchEvent(inputEvent);
        }, 0);
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter((term) => {
                if (this.model && this.model.length > 0) {
                    return true;
                } else {
                    return term.length > 2;
                }
            }),
            switchMap((term) => this.contractsService.searchContracts(term, this.model)),
            map((val) => {
                let contracts = [];

                for (const contract of val.data) {
                    const products = contract?.products?.map((item) => {
                        return item.product;
                    });
                    contracts.push({
                        key: contract.id,
                        value: contract.number,
                        contract: contract,
                        products: products,
                        title: `${contract.number ? contract.number : 'No number - '} ${
                            contract.name ? contract.name : 'No name'
                        } - ${contract.type ? contract.type : 'No type'}`,
                        subtitle: products?.length ? `products: ${this.getSubTitle(products, 1)}` : ``,
                        tooltip: this.getTooltipValue(products, 1),
                        stamp: this.getStampForTootltip(products, 1)
                    });
                }

                if (contracts.length == 0) {
                    contracts.push({
                        key: '',
                        value: 'No contract found.',
                        name: null,
                        title: 'No contract found.'
                    });
                }
                return contracts;
            })
        );

    getSubTitle(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(0, numberValues).join(', ');
        } else {
            return values ? values.join(', ') : '';
        }
    }

    getTooltipValue(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(numberValues).join(', ');
        }
    }

    getStampForTootltip(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return [{ value: '+' + (values.length - numberValues), classList: 'stamp--small' }];
        }
    }

    getContractInfo(contract: Contract) {
        return contract.name;
    }

    onSelect($event) {
        if ($event.item) {
            this.model.push($event.item);
            this.modelChange.emit(this.model);
        }

        $event.preventDefault();

        setTimeout(() => {
            this.selected = '';
        }, 200);
    }

    deleteValue(index) {
        this.model.splice(index, 1);
        this.modelChange.emit(this.model);
    }
}
